import React from "react";
import { HiBellAlert } from "react-icons/hi2";
import { MdSchedule } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";


import benifitimg from "../../assets/imagenew/Benefits.jpg";
import backgroundImage from "../../assets/imagenew/Smarthomebgimge.jpg";
import videoimg from "../../assets/imagenew/about-img1.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
function FireMonitoringSysteminNoida() {
  return (
    <>
     <Helmet>
        <meta
          name="title"
          content="Best Fire Monitoring System in Noida "
        />

        <meta
          name="description"
          content="Enhance safety with our Fire Monitoring System in Noida—real-time alerts and reliable monitoring for comprehensive fire protection"
        />

        <meta
          name="keywords"
          content="Fire Monitoring System in Noida "
        />
      </Helmet>
   <div
        className="inner-banner"
        style={{
          backgroundImage: `url(https://www.wilsonfire.com/wp-content/uploads/2021/08/alarm-3410065_1920.jpg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="inner-title">
            <h1
              className=" sm:font-bold md:font-bold  sm:text-[90px]  md:text-[90px]  "
            style={{color:"Black"}}
            >
              Fire Monitoring System in Noida
             </h1>
            <ul>
              <li
                className="  font-bold text-[68px] "
                style={{color:"Black"}}
              >
                Your Gateway to Secure Living
              </li>
            </ul>
          </div>
        </div>
      </div>
    <div>
      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-play">
                <img src={videoimg} alt="About-Images" />
                <div className="about-play-content">
                  <h1 className="d-none">Smart Home Solution </h1>
                  <span>Watch Our Intro Video</span>
                  <h2>Perfect Solution for Home Automation !</h2>
                  <div className="play-on-area">
                    <Link to="#" className="play-on popup-btn">
                      <i className="bx bx-play"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content ml-25">
                <div className="section-title">
                  <span className="sp-color2">Home Automation</span>
                  <h2>
                    SAR Televenture : Your One-Stop Solutions for Intelligent
                    Automation
                  </h2>
                  <h6 className=" pt-2">
                    Transform your space with SAR Televenture Tailored Home
                    Automation Solutions!
                  </h6>
                  <p>
                    We offer comprehensive automation systems designed to
                    elevate convenience, security, and energy efficiency
                    across diverse applications
                  </p>
                  <p>
                    <span>Homes</span> Experience a smarter lifestyle with
                    effortless control over lighting, thermostats, appliances,
                    and more.
                  </p>
                  <p>
                    <span>Commercial Buildings</span> Enhance operational
                    efficiency, optimize energy use, and create a secure
                    environment for your business.
                  </p>
                  <p>
                    <span> Hotels</span> Deliver an exceptional guest
                    experience with features like in-room temperature control
                    and personalized lighting.
                  </p>
                  <p>
                    <span> Public Utility Sector</span> Leverage our
                    automation expertise to optimize resource management and
                    improve service delivery.
                  </p>
                </div>
                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="choose-area pt-100 pb-70  "
      style={{ backgroundColor: "gray" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="mr-auto place-self-center lg:col-span-7">
             
              <h4 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
              Excellent Fire Safety System in Noida
              </h4>
              <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0 ">
                 <ul className="list-disc text-white">
                <ul>Use the Fusionnet sophisticated fire monitoring system in Noida to safeguard the security and safety of your home. Our cutting-edge technology offers thorough fire detection and alert services, giving residents of this quickly expanding urban area peace of mind.
                </ul>
                 <h4 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
                 Cutting Edge Fire Safety Technology
                 </h4>
                 <ul>Noida has a higher risk of fire occurrences due to its rapid urbanization and dense building. Modern smoke detectors, heat sensors, and sophisticated alarm systems that identify fires in their early stages are features of the Fusionnet fire monitoring system that are intended to meet these issues.
                 </ul>
                 <h4 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
                 Real-Time Surveillance for Quick Action
                 </h4>
                 <ul>The Fusionnet system offers continuous protection for your property with round-the-clock real-time monitoring. The home owner and the closest fire station receive instant alerts in the case of a fire, facilitating a prompt and well-coordinated response. This quick response can save lives and stop significant damage.
                 </ul>
                 <h4 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
                 Interface That's Easy to Use
                 </h4>
                 <ul>Using a mobile app or desktop platform, property owners may monitor the system's status, receive updates, and handle alarms using our user-friendly interface for fire safety monitoring. Your everyday activities will be minimally disrupted by the skilled installation team of Fusionnet, ensuring smooth integration.
                 </ul>
                 <h4 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
                 Make an investment in mental tranquility.
                 </h4>
                 <ul>Selecting Fusionnet's fire monitoring system is an investment in your assets' safety and the health and welfare of the occupants. With dependable protection in the fast-paced metropolitan environment of Noida, our technology makes it easy for you to adhere to safety laws.</ul>
                 
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="choose-img">
              <img src={benifitimg} alt="Images" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className={`security-area pt-100 pb-70 security-area pt-100 pb-70 bg-cover bg-cente  opacity-80`}
      style={{
        backgroundImage: `url(${backgroundImage})`, // Use the imported image
      }}
    >
      <div className="absolute inset-0  opacity-80"></div>
      <div className="container   z-10">
        <div className="section-title text-center z-10 flex  center flex-col items-center lg:ml-[250px]">
          <span className="sp-color4  text-white">Our Process</span>
          <h2 className="leading-3 text-white " style={{ fontSize: "25px" }}>
            We follow an automation process that is flexible, easy to
            personalize & execute.
          </h2>
        </div>
        <div className="row pt-45">
          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-cyber-security"></i>
              <h3>
                <Link to="#">Planning</Link>
              </h3>
              <p>
                Our team consults you on the project at an early planning
                stage and ensures seamless integration of all the components
                of your home.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-implement"></i>
              <h3>
                <Link to="#">Hand-Over</Link>
              </h3>
              <p>
                At the time of hand over, our team thoroughly demonstrates all
                of the smart functionalities of your digital home. tortor
                odio.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-consulting"></i>
              <h3>
                <Link to="#">Servicing</Link>
              </h3>
              <p>
                We provide an AMC contract for timely servicing of the
                automation modules. Our team of experts conducts a complete
                servicing of entire complex.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-computer"></i>
              <h3>
                <Link to="#">Model Home</Link>
              </h3>
              <p>
                We help you set up your model home & personalize it as per the
                requirements of your modern lifestyle.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-effective"></i>
              <h3>
                <Link to="#">Execution</Link>
              </h3>
              <p>
                Once all the structural requirements are met, our team comes
                in for a complete automation installation.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-consultant"></i>
              <h3>
                <Link to="#">Shifting</Link>
              </h3>
              <p>
                Our wire-free automation modules are easy to disconnect &
                re-install at your next home
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section className="   border bg-[#D5E5F4] ">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className=" w-full mb-8 lg:mb-16 flex justify-center items-center flex-col">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-[#0071DC] ">
            Features
          </h2>
          <p className="text-black sm:text-xl ">
            Wire-free Control | Real-time Alerts | Easy Scheduling
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 ">
          <div className="  ">
            <CiMobile3 className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC]  mt-4">
              App Control
            </h3>
            <p className="text-black ">
              Control the entire ecosystem of your smart home from anywhere,
              anytime with our user-friendly phone app with a single touch.
            </p>
          </div>
          <div>
            <HiBellAlert className="text-[#0071DC] text-4xl " />

            <h3 className="mb-2 text-xl font-bold text-[#0071DC]  mt-4">
              Alert
            </h3>
            <p className="text-black ">
              Get live security alerts directly sent to your phone & keep
              track of all that’s happening at your home. Stay stress-free on
              vacations with your home’s security ensured.
            </p>
          </div>
          <div>
            <MdSchedule className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC] mt-4">
              Scheduling
            </h3>
            <p className="text-black ">
              Schedule the run-time of all the appliances at your smart home.
              Pre-set lights, fans, air-conditioner, geyser and television to
              turn ON/OFF at a specific time
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
  );
}

export default FireMonitoringSysteminNoida;
