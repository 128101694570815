import React from "react";

import Videodoorcard from "./Videodoorcard";
import { Helmet } from "react-helmet";
import PagetopCompontswithbg from "./PagetopCompontswithbg";
import banner from "../assets/imagenew/bannervideodoorphone.jpg";
import { TbEyeCheck } from "react-icons/tb";
import { RiRemoteControlLine } from "react-icons/ri";
import { MdOutlineBroadcastOnHome } from "react-icons/md";
import { CgSmartHomeWashMachine } from "react-icons/cg";
import videodoorimg2 from "../assets/imagenew/videodoor45.jpeg";
import videodoorimg1 from "../assets/imagenew/videodoorphone.jpeg";
function Videodoor() {
  return (
    <>
      <Helmet>
        <title> Video Door Phone Solutions | SAR Televenture </title>
        <link
          rel="canonical"
          href="https://sarteleventure.com/video-door-phone"
        />
        <meta
          name="keywords"
          content="Home Automation Solutions, Home Automation Company, Energy Management, Prepaid Meter, Smart CCTV Solutions, Intercom Service Provider, Smart CCTV Solutions In Delhi NCR, Automatic Boom Barrier Solutions, Video Door Phone System, Home Automation Company In Delhi NCR, FTTH, FTTX, GPON, Fire Alarm Company In Delhi NCR, Fire Alarm System, Intercom Service Provider In Delhi NCR"
        />
        <meta
          name="description"
          content=" SAR Televenture provides cutting-edge video door phone solutions for enhanced security and convenience, ensuring peace of mind for your property."
        />
        <meta
          name="title"
          content=" Video Door Phone Solutions | SAR Televenture"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://sarteleventure.com/" />
        <meta
          property="og:image"
          content="https://sarteleventure.com/path_to_image.jpg"
        />
        <meta
          property="og:description"
          content="SAR Televenture offers advanced FTTH solutions, providing high-speed and reliable fiber optic technology for modern telecommunications."
        />
        <meta property="og:site_name" content="SAR Televenture" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:updated_time" content="2024-04-19T00:00:00Z" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="https://sarteleventure.com/" />
        <meta
          name="twitter:description"
          content="SAR Televenture is the top fire alarm system and fire detection system company - designing and offering a variety of fire alarm systems.."
        />
        <meta
          name="twitter:image"
          content="https://sarteleventure.com/assets/imagenew/para-logo.png"
        />
      </Helmet>

      <PagetopCompontswithbg
        Title="VIDEO DOOR PHONE"
        img={banner}
        titleColor="#ffffff"
      />
      <div className="relative flex bg-clip-border sm:p-52 md:p-52 rounded-xl bg-white text-black  w-full flex-col lg:flex-row container">
        <div className="relative w-full lg:w-2/5 m-0 overflow-hidden text-black bg-white rounded-r-none bg-clip-border rounded-xl shrink-0">
          <img
            src={videodoorimg2}
            alt="videodoorimg2"
            className="w-full h-full"
          />
        </div>
        <div className="p-6">
          <h6 className="block mb-4 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-black uppercase">
            Reimagining Home Security with SAR Televenture IP Video Intercom
          </h6>
          <h4 className="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
            Video door phone
          </h4>
          <p className="block mb-8 font-sans text-base antialiased font-normal leading-relaxed text-black pt-8">
            Experience a new era of security and convenience with SAR
            Televenture revolutionary IP Series Video Intercom System. Designed
            for modern living, this advanced system caters to apartments,
            suburban homes, and businesses alike
          </p>
          <p></p>
        </div>
      </div>

      <div className=" flex flex-col  bg-gray-200">
        <div className=" flex justify-center container items-center m-12 flex-col">
          <h4>Unparalleled Security with Cutting-Edge Features</h4>
          <p>
            SAR Televenture IP Service goes beyond traditional intercoms,
            offering unparalleled security and user-friendliness. Here's what
            sets it apart
          </p>
        </div>
        <div class=" mx-auto w-[100%]  container  content-center items-center grid  xl:grid-cols-4 lg:grid-cols-3  sm:grid-cols-2 grid-cols-1 lg:gap-4  sm:gap-4 gap-4 py-4  mb-16">
          <Videodoorcard
            icon={<TbEyeCheck />}
            title="Crystal-Clear Vision"
            description={
              <span>
                High-definition video ensures you see visitors clearly, even in
                challenging lighting conditions. Wide viewing angles provide a
                comprehensive perspective of your entrance. <br /> <br />
              </span>
            }
          />
          <Videodoorcard
            icon={<RiRemoteControlLine class="text-4xl" />}
            title="Effortless Remote Control"
            description={
              <span>
                Intuitive touchscreens make communication, receiving calls,
                monitoring, and managing your intercom system from anywhere,
                anytime, using our Mobile App.
              </span>
            }
          />
          <Videodoorcard
            icon={<MdOutlineBroadcastOnHome />}
            title="Unified Smart Home Control"
            description="The indoor stations act as central hubs, managing all your Hikvision devices with user-friendly interfaces. The Android system allows for seamless integration with third-party applications."
          />
          <Videodoorcard
            icon={<CgSmartHomeWashMachine />}
            title="Smart Access Solutions"
            description={
              <span>
                Enjoy the convenience of touch-free entry with our intelligent
                authentication feature, eliminating the need for physical key
                cards by our video door phone service.. <br /> <br />
              </span>
            }
          />
        </div>
      </div>
      <div
        className="choose-area pt-100 pb-70"
        style={{ backgroundColor: "gray" }}
      >
        <div className="  flex  flex-col lg:flex-row w-[100%] justify-between  items-center h-auto container">
          <div className="  text-white ">
            <div className="  w-[100%]  ">
              <h4 className=" text-white ">
                SAR Televenture IP Video Intercom System for Builders and RWAs
              </h4>
              <p className=" text-white">
                SAR Televenture IP Video Intercom System offers a range of
                advantages for builders and Resident Welfare Associations (RWAs)
                looking to enhance security and resident convenience in their
                societies. Here's a breakdown of the key benefits
              </p>
            </div>
          </div>
          <div className=" w-[100%] flex justify-start px-2">
            <img src={videodoorimg1} alt="videodoorimg2" />
          </div>
        </div>
        <section className="  ">
          <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
            <div className=" w-full  flex justify-center items-center flex-col">
              <h4 className="text-4xl tracking-tight font-bold  text-white">
                Enhanced Security for Residents
              </h4>
              <p className="text-black sm:text-xl "></p>
            </div>
            <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 ">
              <div className=" ">
                <h3 className="mb-2 text-xl  text-white ">
                  Improved Screening
                </h3>
                <p className="text-white ">
                  Residents can visually identify visitors before granting
                  access, deterring unauthorized entry
                </p>
              </div>
              <div>
                <h3 className="mb-2 text-xl  text-white ">
                  Increased Monitoring
                </h3>
                <p className="text-white ">
                  Live video feeds and recording capabilities provide valuable
                  evidence in case of security incidents
                </p>
              </div>
              <div>
                <h3 className="mb-2 text-xl text-white ">
                  Touchless Access Options
                </h3>
                <p className="text-white">
                  Intelligent authentication features eliminate the need for
                  physical keys or cards, reducing the risk of loss or theft
                </p>
              </div>
              <div className=" ">
                <h3 className="mb-2 text-xl  text-white ">
                  Centralized Management
                </h3>
                <p className="text-white ">
                  Manage access control and monitor activity throughout the
                  society from a central location
                </p>
              </div>
              <div>
                <h3 className="mb-2 text-xl  text-white ">
                  Reduced Maintenance Costs
                </h3>
                <p className="text-white ">
                  Durable design and remote monitoring capabilities minimize
                  maintenance needs.
                </p>
              </div>
              <div>
                <h3 className="mb-2 text-xl text-white ">
                  Cost-Effective Solutions
                </h3>
                <p className="text-white">
                  SAR Televenture expertise ensures optimized system design for
                  cost-efficiency.
                </p>
              </div>
            </div>
          </div>
          <div className=" px-4 mx-auto max-w-screen-xl  lg:px-6">
            <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 "></div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Videodoor;
