import React from "react";
import Banner from "./Banner";
import Herosection from "./Herosection";
import HomeAbout from "./HomeAbout";
import OurServices from "./OurServices";

import CaseStudy from "./CaseStudy";

import { Helmet } from "react-helmet";
// import Blog from "./Blog";
import Crawsal from "./Crausal";

function Home() {
  return (
    <div>
      <Helmet>
        <title>SAR TeleVenture Limited | Advanced Fiber Optic Technology</title>
        <link rel="canonical" href="https://sarteleventure.com/" />
        <meta
          name="keywords"
          content="Home Automation Solutions, Home Automation Company, Energy Management, Prepaid Meter, Smart CCTV Solutions, Intercom Service Provider, Smart CCTV Solutions In Delhi NCR, Automatic Boom Barrier Solutions, Video Door Phone System, Home Automation Company In Delhi NCR, FTTH, FTTX, GPON, Fire Alarm Company In Delhi NCR, Fire Alarm System, Intercom Service Provider In Delhi NCR’ infra tower"
        />
        <meta
          name="description"
          content=" SAR Televenture is India's largest wireless infra provider company, delivering Tower infra, FTTH infra, smart prepaid meter, smart home automation, video door phone, and CCTV Camera solutions"
        />
        <meta
          name="title"
          content=" SAR TeleVenture Limited | Advanced Fiber Optic Technology"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://sarteleventure.com/" />
        <meta
          property="og:image"
          content="https://sarteleventure.com/path_to_image.jpg"
        />
        <meta
          property="og:description"
          content="SAR Televenture offers advanced FTTH solutions, providing high-speed and reliable fiber optic technology for modern telecommunications."
        />
        <meta property="og:site_name" content="SAR Televenture" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:updated_time" content="2024-04-19T00:00:00Z" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="https://sarteleventure.com/" />
        <meta
          name="twitter:description"
          content="SAR Televenture is the top fire alarm system and fire detection system company - designing and offering a variety of fire alarm systems.."
        />
        <meta
          name="twitter:image"
          content="https://sarteleventure.com/assets/imagenew/para-logo.png"
        />
      </Helmet>

      <Banner />

      <Herosection />

      <HomeAbout />

      <OurServices />

      <CaseStudy />

      {/* <Blog /> */}
      <Crawsal />
    </div>
  );
}

export default Home;
