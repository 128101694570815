import React from "react";
import { HiBellAlert } from "react-icons/hi2";
import { MdSchedule } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";


import benifitimg from "../../assets/imagenew/Benefits.jpg";
import backgroundImage from "../../assets/imagenew/Smarthomebgimge.jpg";
import videoimg from "../../assets/imagenew/about-img1.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
function HomeAutomationIoTinNoida() {
  return (
    <>
     <Helmet>
        <meta
          name="title"
          content="Best Home Automation iot in Noida"
        />

        <meta
          name="description"
          content="Experience cutting-edge home automation IoT in Noida—seamless device integration, smart control, and enhanced convenience for modern living"
        />

        <meta
          name="keywords"
          content="home automation iot in Noida"
        />
      </Helmet>
   <div
        className="inner-banner"
        style={{
          backgroundImage: `url(http://localhost:3000/static/media/ftthfirstimg.f2741f490a58de090731.jpg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="inner-title">
            <h1
              className=" sm:font-bold md:font-bold  sm:text-[90px]  md:text-[90px]  "
            style={{color:"white"}}
            >
              Home Automation IoT in Noida
            </h1>
            <ul>
              <li
                className="  font-bold text-[68px] "
            
              >
                Your Gateway to Secure Living
              </li>
            </ul>
          </div>
        </div>
      </div>
    <div>
      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-play">
                <img src={videoimg} alt="About-Images" />
                <div className="about-play-content">
                  <h1 className="d-none">Smart Home Solution </h1>
                  <span>Watch Our Intro Video</span>
                  <h2>Perfect Solution for Home Automation !</h2>
                  <div className="play-on-area">
                    <Link to="#" className="play-on popup-btn">
                      <i className="bx bx-play"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content ml-25">
                <div className="section-title">
                  <span className="sp-color2">Home Automation</span>
                  <h2>
                    SAR Televenture : Your One-Stop Solutions for Intelligent
                    Automation
                  </h2>
                  <h6 className=" pt-2">
                    Transform your space with SAR Televenture Tailored Home
                    Automation Solutions!
                  </h6>
                  <p>
                    We offer comprehensive automation systems designed to
                    elevate convenience, security, and energy efficiency
                    across diverse applications
                  </p>
                  <p>
                    <span>Homes</span> Experience a smarter lifestyle with
                    effortless control over lighting, thermostats, appliances,
                    and more.
                  </p>
                  <p>
                    <span>Commercial Buildings</span> Enhance operational
                    efficiency, optimize energy use, and create a secure
                    environment for your business.
                  </p>
                  <p>
                    <span> Hotels</span> Deliver an exceptional guest
                    experience with features like in-room temperature control
                    and personalized lighting.
                  </p>
                  <p>
                    <span> Public Utility Sector</span> Leverage our
                    automation expertise to optimize resource management and
                    improve service delivery.
                  </p>
                </div>
                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="choose-area pt-100 pb-70  "
      style={{ backgroundColor: "gray" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="mr-auto place-self-center lg:col-span-7">
              <h1 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
              Home Automation IoT in Noida
              </h1>
              <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0 ">
                <ul className="list-disc text-white">
                  <li>Welcome to Sar Televenture, where we redefine your living experience with cutting-edge Home Automation IoT in Noida. Embrace the future of home living with our advanced IoT solutions that seamlessly integrate technology into your everyday life, making your home smarter, more efficient, and incredibly intuitive.
                  </li>
                  <li>Picture a home where every device is interconnected—lights, climate control, security systems, and more— all managed effortlessly through your smartphone or voice commands. With Sar Televenture’s home automation IoT systems, this vision becomes a reality. Our technology allows you to customize your environment to fit your preferences, enhancing both comfort and convenience.
                  </li>
                  <li>Our Home Automation IoT solutions are designed to adapt to your lifestyle in Noida’s vibrant and evolving landscape. Whether you’re looking to create a cozy atmosphere with adjustable lighting, keep your home secure with real-time surveillance, or manage energy consumption to save on bills, our systems offer effortless control and efficiency.
                  </li>
                  <li>At Sar Televenture, we understand that every home and every user is unique. That’s why we tailor our IoT solutions to meet your specific needs, ensuring that our technology integrates smoothly with your lifestyle. Our expert team works closely with you to design and implement a system that provides both practicality and innovation.
                  </li>
                  <li>Step into the future with Home Automation IoT in Noida and let Sar Televenture enhance your living space with smart, connected technology. Contact us today to discover how our solutions can transform your home into a hub of modern convenience and efficiency.
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="choose-img">
              <img src={benifitimg} alt="Images" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className={`security-area pt-100 pb-70 security-area pt-100 pb-70 bg-cover bg-cente  opacity-80`}
      style={{
        backgroundImage: `url(${backgroundImage})`, // Use the imported image
      }}
    >
      <div className="absolute inset-0  opacity-80"></div>
      <div className="container   z-10">
        <div className="section-title text-center z-10 flex  center flex-col items-center lg:ml-[250px]">
          <span className="sp-color4  text-white">Our Process</span>
          <h2 className="leading-3 text-white " style={{ fontSize: "25px" }}>
            We follow an automation process that is flexible, easy to
            personalize & execute.
          </h2>
        </div>
        <div className="row pt-45">
          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-cyber-security"></i>
              <h3>
                <Link to="#">Planning</Link>
              </h3>
              <p>
                Our team consults you on the project at an early planning
                stage and ensures seamless integration of all the components
                of your home.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-implement"></i>
              <h3>
                <Link to="#">Hand-Over</Link>
              </h3>
              <p>
                At the time of hand over, our team thoroughly demonstrates all
                of the smart functionalities of your digital home. tortor
                odio.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-consulting"></i>
              <h3>
                <Link to="#">Servicing</Link>
              </h3>
              <p>
                We provide an AMC contract for timely servicing of the
                automation modules. Our team of experts conducts a complete
                servicing of entire complex.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-computer"></i>
              <h3>
                <Link to="#">Model Home</Link>
              </h3>
              <p>
                We help you set up your model home & personalize it as per the
                requirements of your modern lifestyle.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-effective"></i>
              <h3>
                <Link to="#">Execution</Link>
              </h3>
              <p>
                Once all the structural requirements are met, our team comes
                in for a complete automation installation.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-consultant"></i>
              <h3>
                <Link to="#">Shifting</Link>
              </h3>
              <p>
                Our wire-free automation modules are easy to disconnect &
                re-install at your next home
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section className="   border bg-[#D5E5F4] ">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className=" w-full mb-8 lg:mb-16 flex justify-center items-center flex-col">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-[#0071DC] ">
            Features
          </h2>
          <p className="text-black sm:text-xl ">
            Wire-free Control | Real-time Alerts | Easy Scheduling
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 ">
          <div className="  ">
            <CiMobile3 className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC]  mt-4">
              App Control
            </h3>
            <p className="text-black ">
              Control the entire ecosystem of your smart home from anywhere,
              anytime with our user-friendly phone app with a single touch.
            </p>
          </div>
          <div>
            <HiBellAlert className="text-[#0071DC] text-4xl " />

            <h3 className="mb-2 text-xl font-bold text-[#0071DC]  mt-4">
              Alert
            </h3>
            <p className="text-black ">
              Get live security alerts directly sent to your phone & keep
              track of all that’s happening at your home. Stay stress-free on
              vacations with your home’s security ensured.
            </p>
          </div>
          <div>
            <MdSchedule className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC] mt-4">
              Scheduling
            </h3>
            <p className="text-black ">
              Schedule the run-time of all the appliances at your smart home.
              Pre-set lights, fans, air-conditioner, geyser and television to
              turn ON/OFF at a specific time
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
  );
}

export default HomeAutomationIoTinNoida;
