import React from "react";
import { HiBellAlert } from "react-icons/hi2";
import { MdSchedule } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";


import benifitimg from "../../assets/imagenew/Benefits.jpg";
import videoimg from "../../assets/imagenew/about-img1.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import OurServices from "../OurServices";
function Chandigarh() {
  return (
    <>
     <Helmet>
        <meta
          name="title"
          content="Intercom Service Provider in Ghaziabad | Ftth Infra | Sar Televenture"
        />

        <meta
          name="description"
          content="Sar Televenture is a top intercom service provider in Ghaziabad, specializing in FTTH infrastructure, tower infrastructure, and home automation solutions."
        />

        <meta
          name="keywords"
          content="tower infrastructure company in Ghaziabad,tower infrastructure company in Ghaziabad,tower company in Ghaziabad,telecom tower infrastructure company in Ghaziabad,cell tower infrastructure companies in Ghaziabad,infra tower company  in Ghaziabad,Ftth infrastructure company in Ghaziabad,Best ftth infrastructure company in Ghaziabad,Small Cell Infrastructure in Ghaziabad,Small cell infrastructure company in Ghaziabad,Intercom services in Ghaziabad,Intercom service provider in Ghaziabad,tower installation company in Ghaziabad,all Ghaziabad tower company,Ghaziabad tower company,top tower company in Ghaziabad,Tower Installation Service in Ghaziabad,tower infrastructure company in Ghaziabad,Best tower company in Ghaziabad,telecom tower infrastructure company in in Ghaziabad,telecom tower infrastructure company in Ghaziabad,cell tower infrastructure companies in Ghaziabad,infra tower company in Ghaziabad,Ftth infrastructure company in Ghaziabad,Small Cell Infrastructure in Ghaziabad,Intercom services in Ghaziabad,Intercom service provider in Ghaziabad,Energy Management Solutions in Ghaziabad,Fire Alarm Solution in Ghaziabad,Fire Alarm System in Ghaziabad,Fire Detection And Fire Alarm System in Ghaziabad,Fire Monitoring System in Ghaziabad,Home Automation And Security System in Ghaziabad,Home Automation Company in Ghaziabad,Home Automation Solutions in Ghaziabad,Prepaid Energy Meter in Ghaziabad,Security Camera Company in Ghaziabad,Security Solution in Ghaziabad,Smart CCTV Solutions in Ghaziabad,Smart Home Automation in Ghaziabad,Smart home solutions in Ghaziabad,Smart Home Systems in Ghaziabad,Smart Prepaid Meter in Ghaziabad,Intercom Service Provider in Ghaziabad,smart automation in Ghaziabad,home automation iot in Ghaziabad,smart home automation in Ghaziabad,home domotics in Ghaziabad,Access Control Systems in Ghaziabad,Boom Barrier Integrated Solutions in Ghaziabad,CCTV Service in Ghaziabad"
        />
      </Helmet>
   <div
        className="inner-banner"
        style={{
          backgroundImage: `url(https://images.squarespace-cdn.com/content/v1/587e1dd33a04118eabe07a9d/1485147131500-98TSRSF5EH877OSFBIOP/telecom_banner.jpg?format=2500w)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="inner-title">
            <h1
              className=" sm:font-bold md:font-bold  sm:text-[90px]  md:text-[90px]  "
            style={{color:"Black"}}
            >
            </h1>
            <ul>
              <li
                className="  font-bold text-[68px] "
                style={{color:"Black"}}
              >
              </li>
            </ul>
          </div>
        </div>
      </div>
    <div>
      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-play">
                <img src={videoimg} alt="About-Images" />
                <div className="about-play-content">
                  <h1 className="d-none">Smart Home Solution </h1>
                  <span>Watch Our Intro Video</span>
                  <h2>Perfect Solution for Home Automation !</h2>
                  <div className="play-on-area">
                    <Link to="#" className="play-on popup-btn">
                      <i className="bx bx-play"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content ml-25">
                <div className="section-title">
                
                  <h2>Elevate Your Business with Our Solutions
                  </h2>
                  
                  <p>
                  As leaders in Ghaziabad, we excel in designing, deploying, integrating, and managing state-of-the-art communication networks. Our comprehensive platform supports high-performance fiber systems, delivering scalable and future-ready solutions. Collaborating with India’s foremost developers, we assist in constructing homes that feature advanced digital services, including IP and IoT technologies, security systems, energy management, and smart home automation—customized to fulfill individual requirements.
                  </p>
                </div>
                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="choose-area pt-100 pb-70  "
      style={{ backgroundColor: "gray" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="mr-auto place-self-center lg:col-span-7">
             
              <h4 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
              Comprehensive Infrastructure and Automation Services in Ghaziabad
              </h4>
              <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0 ">
                <ul className="list-disc text-white">
                  <ul>We provide a comprehensive range of innovative infrastructure and automation services in Ghaziabad, tailored to meet the needs of modern living and business operations. Specializing in tower infrastructure and telecom tower infrastructure, we ensure robust communication networks that enhance connectivity. Our FTTH infrastructure solutions deliver high-speed fiber connectivity for both residential and commercial clients, while our small cell services maximize wireless coverage and performance. We prioritize security with advanced video door phone systems, CCTV services, and access control solutions that safeguard your property. Additionally, our home automation services integrate technology for convenience and efficiency, and our boom barrier systems effectively manage traffic flow and secure entry points. As a leading FTTH infrastructure company in Ghaziabad, we are committed to delivering high-quality, tailored solutions that address your unique infrastructure and automation needs.

                  </ul>
                  
                 
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="choose-img">
              <img src={benifitimg} alt="Images" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <OurServices/>
    <section className="   border bg-[#D5E5F4] ">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className=" w-full mb-8 lg:mb-16 flex justify-center items-center flex-col">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-[#0071DC] ">
            Features
          </h2>
          <p className="text-black sm:text-xl ">
            Wire-free Control | Real-time Alerts | Easy Scheduling
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 ">
          <div className="  ">
            <CiMobile3 className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC]  mt-4">
              App Control
            </h3>
            <p className="text-black ">
              Control the entire ecosystem of your smart home from anywhere,
              anytime with our user-friendly phone app with a single touch.
            </p>
          </div>
          <div>
            <HiBellAlert className="text-[#0071DC] text-4xl " />

            <h3 className="mb-2 text-xl font-bold text-[#0071DC]  mt-4">
              Alert
            </h3>
            <p className="text-black ">
              Get live security alerts directly sent to your phone & keep
              track of all that’s happening at your home. Stay stress-free on
              vacations with your home’s security ensured.
            </p>
          </div>
          <div>
            <MdSchedule className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC] mt-4">
              Scheduling
            </h3>
            <p className="text-black ">
              Schedule the run-time of all the appliances at your smart home.
              Pre-set lights, fans, air-conditioner, geyser and television to
              turn ON/OFF at a specific time
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
  );
}

export default Chandigarh;
