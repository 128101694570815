import React from "react";
import { HiBellAlert } from "react-icons/hi2";
import { MdSchedule } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";


import benifitimg from "../../assets/imagenew/Benefits.jpg";
import videoimg from "../../assets/imagenew/about-img1.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import OurServices from "../OurServices";
function Demo() {
  return (
    <>
     <Helmet>
        <meta
          name="title"
          content="Best Fire Alarm System | Fire Alarm System"
        />

        <meta
          name="description"
          content="Protect your space with the best fire alarm system. Rapid detection, reliable alerts, and cutting-edge technology for ultimate safety"
        />

        <meta
          name="keywords"
          content="Best Fire Alarm System"
        />
      </Helmet>
   <div
        className="inner-banner"
        style={{
          backgroundImage: `url(https://www.wilsonfire.com/wp-content/uploads/2021/08/alarm-3410065_1920.jpg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="inner-title">
            <h1
              className=" sm:font-bold md:font-bold  sm:text-[90px]  md:text-[90px]  "
            style={{color:"Black"}}
            >
              Best Fire Alarm System
            </h1>
            <ul>
              <li
                className="  font-bold text-[68px] "
                style={{color:"Black"}}
              >
                Your Gateway to Secure Living
              </li>
            </ul>
          </div>
        </div>
      </div>
    <div>
      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-play">
                <img src={videoimg} alt="About-Images" />
                <div className="about-play-content">
                  <h1 className="d-none">Smart Home Solution </h1>
                  <span>Watch Our Intro Video</span>
                  <h2>Perfect Solution for Home Automation !</h2>
                  <div className="play-on-area">
                    <Link to="#" className="play-on popup-btn">
                      <i className="bx bx-play"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content ml-25">
                <div className="section-title">
                
                  <h2>
                  Boost Your Business with Our Solutions

                  </h2>
                  
                  <p>
                  As pioneers in Noida, we specialize in designing, deploying, integrating, and managing cutting-edge communication networks. Our all-in-one platform powers high-performance fiber systems, providing future-proof solutions. Partnering with India’s top developers, we help build homes equipped with advanced digital services, including IP and IoT systems, security, energy management, and home automation—tailored to meet unique needs.
                  </p>
                </div>
                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="choose-area pt-100 pb-70  "
      style={{ backgroundColor: "gray" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="mr-auto place-self-center lg:col-span-7">
             
              <h4 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
              Comprehensive Infrastructure and Automation Solutions in Noida
              </h4>
              <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0 ">
                <ul className="list-disc text-white">
                  <ul>We are dedicated to offering comprehensive infrastructure and automation solutions in Noida, aimed at enhancing both residential and commercial spaces. Our services range from telecom tower infrastructure and FTTH (Fiber to the Home) connectivity to small cell solutions that improve network coverage and capacity. We also provide advanced home automation systems, integrating comfort, security, and energy efficiency into daily living. Our offerings include modern video door phone systems for added security, CCTV services for round-the-clock surveillance, and access control systems to ensure secure entry. Additionally, we specialize in boom barrier solutions for efficient traffic management. With a focus on innovation and high-performance technology, we tailor our solutions to meet the evolving needs of our clients, ensuring seamless connectivity and security across all aspects of their spaces.
                  </ul>
                  <h4 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
                  Why Choose Sar Televenture Fire Alarm Systems?
                  </h4>
                  <ol><span className=" font-bold">1. State-of-the-Art Technology:</span> Our fire alarm systems are equipped with the latest in detection technology, including advanced sensors that can quickly identify smoke, heat, and fire. This ensures a rapid response, minimizing potential damage and enhancing safety.</ol>
                  <ol><span className=" font-bold">2. Tailored Solutions:</span> We understand that every building has its own unique requirements. Our systems are fully customizable to fit the specific needs of residential, commercial, and industrial properties, providing comprehensive coverage and protection.</ol>
                  <ol><span className=" font-bold">3. Easy Integration:</span> Our fire alarm systems are designed to seamlessly integrate with existing security and automation systems. This allows for a unified approach to safety, with centralized monitoring and control for maximum efficiency.</ol>
                  <ol><span className=" font-bold">4. Exceptional Reliability:</span> Built to withstand the test of time, our fire alarm systems are known for their reliability and durability. They undergo rigorous testing to ensure they perform flawlessly when it matters most, offering peace of mind to our clients.</ol>
                  <ol><span className=" font-bold">5. Continuous Monitoring and Support:</span> Sar Televenture offers 24/7 monitoring services to ensure immediate action in the event of a fire. Our dedicated support team is always on standby, ready to provide maintenance and emergency services as needed.</ol>
                  <br></br>
                  <ul>Invest in the best fire alarm system with Sar Televenture Secure your property and protect lives with our superior fire protection solutions. Contact us today to learn more about how we can enhance your safety infrastructure.
                  </ul>
                 
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="choose-img">
              <img src={benifitimg} alt="Images" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <OurServices/>
    <section className="   border bg-[#D5E5F4] ">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className=" w-full mb-8 lg:mb-16 flex justify-center items-center flex-col">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-[#0071DC] ">
            Features
          </h2>
          <p className="text-black sm:text-xl ">
            Wire-free Control | Real-time Alerts | Easy Scheduling
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 ">
          <div className="  ">
            <CiMobile3 className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC]  mt-4">
              App Control
            </h3>
            <p className="text-black ">
              Control the entire ecosystem of your smart home from anywhere,
              anytime with our user-friendly phone app with a single touch.
            </p>
          </div>
          <div>
            <HiBellAlert className="text-[#0071DC] text-4xl " />

            <h3 className="mb-2 text-xl font-bold text-[#0071DC]  mt-4">
              Alert
            </h3>
            <p className="text-black ">
              Get live security alerts directly sent to your phone & keep
              track of all that’s happening at your home. Stay stress-free on
              vacations with your home’s security ensured.
            </p>
          </div>
          <div>
            <MdSchedule className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC] mt-4">
              Scheduling
            </h3>
            <p className="text-black ">
              Schedule the run-time of all the appliances at your smart home.
              Pre-set lights, fans, air-conditioner, geyser and television to
              turn ON/OFF at a specific time
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
  );
}

export default Demo;
