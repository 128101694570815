import React from "react";
import { HiBellAlert } from "react-icons/hi2";
import { MdSchedule } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";


import benifitimg from "../../assets/imagenew/Benefits.jpg";
import videoimg from "../../assets/imagenew/about-img1.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import OurServices from "../OurServices";
function Pune() {
  return (
    <>
     <Helmet>
        <meta
          name="title"
          content="Telecom Tower Infrastructure Company in Pune | Smart CCTV | Sar Televenture"
        />

        <meta
          name="description"
          content="Description:  Sar Televenture is a leading telecom tower infrastructure company in Pune, offering FTTH, tower infra, home automation, fire alarms, and smart CCTV solutions."/>

        <meta
          name="keywords"
          content="tower infrastructure company in Pune,tower infrastructure company in Pune,tower company in Pune,telecom tower infrastructure company in Pune,cell tower infrastructure companies in Pune,infra tower company  in Pune,Ftth infrastructure company in Pune,Best ftth infrastructure company in Pune,Small Cell Infrastructure in Pune,Small cell infrastructure company in Pune,Intercom services in Pune,Intercom service provider in Pune,tower installation company in Pune,all Pune tower company,Pune tower company,top tower company in Pune,Tower Installation Service in Pune,tower infrastructure company in Pune,Best tower company in Pune,telecom tower infrastructure company in in Pune,telecom tower infrastructure company in Pune,cell tower infrastructure companies in Pune,infra tower company in Pune,Ftth infrastructure company in Pune,Small Cell Infrastructure in Pune,Intercom services in Pune,Intercom service provider in Pune,Energy Management Solutions in Pune,Fire Alarm Solution in Pune,Fire Alarm System in Pune,Fire Detection And Fire Alarm System in Pune,Fire Monitoring System in Pune,Home Automation And Security System in Pune,Home Automation Company in Pune,Home Automation Solutions in Pune,Prepaid Energy Meter in Pune,Security Camera Company in Pune,Security Solution in Pune,Smart CCTV Solutions in Pune,Smart Home Automation in Pune,Smart home solutions in Pune,Smart Home Systems in Pune,Smart Prepaid Meter in Pune,Intercom Service Provider in Pune,smart automation in Pune,home automation iot in Pune,smart home automation in Pune,home domotics in Pune,Access Control Systems in Pune,Boom Barrier Integrated Solutions in Pune,CCTV Service in Pune
          "/>
      </Helmet>
   <div
        className="inner-banner"
        style={{
          backgroundImage: `url(https://images.squarespace-cdn.com/content/v1/587e1dd33a04118eabe07a9d/1485147131500-98TSRSF5EH877OSFBIOP/telecom_banner.jpg?format=2500w)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="inner-title">
            <h1
              className=" sm:font-bold md:font-bold  sm:text-[90px]  md:text-[90px]  "
            style={{color:"Black"}}
            >
            </h1>
            <ul>
              <li
                className="  font-bold text-[68px] "
                style={{color:"Black"}}
              >
              </li>
            </ul>
          </div>
        </div>
      </div>
    <div>
      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-play">
                <img src={videoimg} alt="About-Images" />
                <div className="about-play-content">
                  <h1 className="d-none">Smart Home Solution </h1>
                  <span>Watch Our Intro Video</span>
                  <h2>Perfect Solution for Home Automation !</h2>
                  <div className="play-on-area">
                    <Link to="#" className="play-on popup-btn">
                      <i className="bx bx-play"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content ml-25">
                <div className="section-title">
                
                  <h2>Accelerate Your Business Growth with Our Innovative Solutions</h2>
                  
                  <p>As pioneers in Pune, we focus on the design, deployment, integration, and management of cutting-edge communication networks. Our comprehensive platform offers high-performance fiber systems, ensuring long-lasting and scalable solutions. By collaborating with India’s top developers, we enable the development of future-ready homes equipped with top-tier digital services, including IP and IoT technologies, advanced security systems, energy management, and smart home automation—customized to fulfill specific needs and elevate modern living experiences.
                  </p>
                </div>
                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="choose-area pt-100 pb-70  "
      style={{ backgroundColor: "gray" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="mr-auto place-self-center lg:col-span-7">
             
              <h4 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
              Comprehensive Infrastructure and Security Solutions in Pune
              </h4>
              <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0 ">
                <ul className="list-disc text-white">
                  <ul>We are a premier tower infrastructure company in Pune, dedicated to enhancing connectivity and security for residential and commercial spaces. Our expertise spans a wide range of services, including tower infrastructure and telecom tower infrastructure, providing reliable communication networks essential for today’s digital landscape. Our FTTH infrastructure solutions deliver high-speed fiber optic internet access, ensuring seamless connectivity for all users. To optimize network performance in urban areas, we specialize in small cell solutions. Security is paramount; our advanced video door phone systems, CCTV services, and access control technologies offer comprehensive protection for properties. Additionally, our home automation services integrate smart technologies for improved efficiency, while our boom barrier systems effectively manage entry points for enhanced safety. As a trusted partner in Pune, we are committed to delivering tailored solutions that meet the unique needs of our clients, helping create safe, efficient, and technologically advanced environments.</ul>
                  
                 
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="choose-img">
              <img src={benifitimg} alt="Images" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <OurServices/>
    <section className="   border bg-[#D5E5F4] ">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className=" w-full mb-8 lg:mb-16 flex justify-center items-center flex-col">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-[#0071DC] ">
            Features
          </h2>
          <p className="text-black sm:text-xl ">
            Wire-free Control | Real-time Alerts | Easy Scheduling
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 ">
          <div className="  ">
            <CiMobile3 className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC]  mt-4">
              App Control
            </h3>
            <p className="text-black ">
              Control the entire ecosystem of your smart home from anywhere,
              anytime with our user-friendly phone app with a single touch.
            </p>
          </div>
          <div>
            <HiBellAlert className="text-[#0071DC] text-4xl " />

            <h3 className="mb-2 text-xl font-bold text-[#0071DC]  mt-4">
              Alert
            </h3>
            <p className="text-black ">
              Get live security alerts directly sent to your phone & keep
              track of all that’s happening at your home. Stay stress-free on
              vacations with your home’s security ensured.
            </p>
          </div>
          <div>
            <MdSchedule className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC] mt-4">
              Scheduling
            </h3>
            <p className="text-black ">
              Schedule the run-time of all the appliances at your smart home.
              Pre-set lights, fans, air-conditioner, geyser and television to
              turn ON/OFF at a specific time
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
  );
}

export default Pune;
