import React from "react";
import { HiBellAlert } from "react-icons/hi2";
import { MdSchedule } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";


import benifitimg from "../../assets/imagenew/Benefits.jpg";
import videoimg from "../../assets/imagenew/about-img1.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import OurServices from "../OurServices";
function Chandigarh() {
  return (
    <>
     <Helmet>
        <meta
          name="title"
          content="Ftth Infrastructure Company in Chandigarh| Small Cell | Sar Televenture"
        />

        <meta
          name="description"
          content="Sar Televenture is a leading small cell infrastructure company in Chandigarh, specializing in FTTH, telecom, and tower infrastructure solutions for seamless connectivity."
        />

        <meta
          name="keywords"
          content="tower infrastructure company in Chandigarh,tower infrastructure company in Chandigarh,tower company in Chandigarh,telecom tower infrastructure company in Chandigarh,cell tower infrastructure companies in Chandigarh,infra tower company  in Chandigarh,Ftth infrastructure company in Chandigarh,Best ftth infrastructure company in Chandigarh,Small Cell Infrastructure in Chandigarh,Small cell infrastructure company in Chandigarh,Intercom services in Chandigarh,Intercom service provider in Chandigarh,tower installation company in Chandigarh,all Chandigarh tower company,Chandigarh tower company,top tower company in Chandigarh,Tower Installation Service in Chandigarh,tower infrastructure company in Chandigarh,Best tower company in Chandigarh,telecom tower infrastructure company in in Chandigarh,telecom tower infrastructure company in Chandigarh,cell tower infrastructure companies in Chandigarh,infra tower company in Chandigarh,Ftth infrastructure company in Chandigarh,Small Cell Infrastructure in Chandigarh,Intercom services in Chandigarh,Intercom service provider in Chandigarh,Energy Management Solutions in Chandigarh,Fire Alarm Solution in Chandigarh,Fire Alarm System in Chandigarh,Fire Detection And Fire Alarm System in Chandigarh,Fire Monitoring System in Chandigarh,Home Automation And Security System in Chandigarh,Home Automation Company in Chandigarh,Home Automation Solutions in Chandigarh,Prepaid Energy Meter in Chandigarh,Security Camera Company in Chandigarh,Security Solution in Chandigarh,Smart CCTV Solutions in Chandigarh,Smart Home Automation in Chandigarh,Smart home solutions in Chandigarh,Smart Home Systems in Chandigarh,Smart Prepaid Meter in Chandigarh,Intercom Service Provider in Chandigarh,smart automation in Chandigarh,home automation iot in Chandigarh,smart home automation in Chandigarh,home domotics in Chandigarh,Access Control Systems in Chandigarh,Boom Barrier Integrated Solutions in Chandigarh,CCTV Service in Chandigarh
"
        />
      </Helmet>
   <div
        className="inner-banner"
        style={{
          backgroundImage: `url(https://images.squarespace-cdn.com/content/v1/587e1dd33a04118eabe07a9d/1485147131500-98TSRSF5EH877OSFBIOP/telecom_banner.jpg?format=2500w)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="inner-title">
            <h1
              className=" sm:font-bold md:font-bold  sm:text-[90px]  md:text-[90px]  "
            style={{color:"Black"}}
            >
            </h1>
            <ul>
              <li
                className="  font-bold text-[68px] "
                style={{color:"Black"}}
              >
              </li>
            </ul>
          </div>
        </div>
      </div>
    <div>
      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-play">
                <img src={videoimg} alt="About-Images" />
                <div className="about-play-content">
                  <h1 className="d-none">Smart Home Solution </h1>
                  <span>Watch Our Intro Video</span>
                  <h2>Perfect Solution for Home Automation !</h2>
                  <div className="play-on-area">
                    <Link to="#" className="play-on popup-btn">
                      <i className="bx bx-play"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content ml-25">
                <div className="section-title">
                
                  <h2>Accelerate Your Business Growth with Our Advanced Solutions
                  </h2>
                  
                  <p>
                  As trailblazers in Chandigarh, we offer expertise in designing, deploying, integrating, and managing state-of-the-art communication networks. Our comprehensive platform delivers high-performance fiber systems, ensuring long-lasting, scalable solutions. Collaborating with India’s leading developers, we help create future-ready homes with top-tier digital services, including IP and IoT technologies, robust security systems, energy management, and smart home automation—customized to suit specific needs and elevate modern living experiences.
                  </p>
                </div>
                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="choose-area pt-100 pb-70  "
      style={{ backgroundColor: "gray" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="mr-auto place-self-center lg:col-span-7">
             
              <h4 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
              Comprehensive Infrastructure and Automation Solutions in Chandigarh
              </h4>
              <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0 ">
                <ul className="list-disc text-white">
                  <ul>We provide comprehensive infrastructure and automation solutions across Chandigarh, offering a range of services including tower infrastructure, FTTH infrastructure, and telecom tower infrastructure to ensure seamless connectivity. Our small cell solutions enhance wireless network performance, while our video door phone, CCTV services, and access control systems provide advanced security for homes and businesses. We also specialize in home automation to bring efficiency and comfort to living spaces, and our boom barrier systems ensure smooth traffic management at entry points. As a leading FTTH infrastructure company in Chandigarh, we are committed to delivering tailored, high-performance solutions that meet the growing demands of modern infrastructure and security needs.

                  </ul>
                  
                 
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="choose-img">
              <img src={benifitimg} alt="Images" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <OurServices/>
    <section className="   border bg-[#D5E5F4] ">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className=" w-full mb-8 lg:mb-16 flex justify-center items-center flex-col">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-[#0071DC] ">
            Features
          </h2>
          <p className="text-black sm:text-xl ">
            Wire-free Control | Real-time Alerts | Easy Scheduling
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 ">
          <div className="  ">
            <CiMobile3 className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC]  mt-4">
              App Control
            </h3>
            <p className="text-black ">
              Control the entire ecosystem of your smart home from anywhere,
              anytime with our user-friendly phone app with a single touch.
            </p>
          </div>
          <div>
            <HiBellAlert className="text-[#0071DC] text-4xl " />

            <h3 className="mb-2 text-xl font-bold text-[#0071DC]  mt-4">
              Alert
            </h3>
            <p className="text-black ">
              Get live security alerts directly sent to your phone & keep
              track of all that’s happening at your home. Stay stress-free on
              vacations with your home’s security ensured.
            </p>
          </div>
          <div>
            <MdSchedule className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC] mt-4">
              Scheduling
            </h3>
            <p className="text-black ">
              Schedule the run-time of all the appliances at your smart home.
              Pre-set lights, fans, air-conditioner, geyser and television to
              turn ON/OFF at a specific time
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
  );
}

export default Chandigarh;
