import React, { useEffect, useState } from "react";
import PagetopCompontswithbg from "./PagetopCompontswithbg";
import { Link } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa";
import img from "../assets/imagenew/smallcell.jpg";
import { Helmet } from "react-helmet";

function Investor() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [data, setData] = useState([]);
  const [documentLink, setDocumentLink] = useState(""); // State for document link
  const [loading, setLoading] = useState(true);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    fetch("https://saradminnew.brandbell.in/public/api/investor_relation")
      .then((response) => response.json())
      .then((responseData) => {
        // Assuming responseData.data is an array of objects with a 'created_at' field
        const sortedData = responseData.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setData(sortedData);
        setDocumentLink(responseData.document_link); // Set document link
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title> SAR Televenture: Investor Relations Growth Opportunities</title>
        <link rel="canonical" href="https://sarteleventure.com/Investor" />
        <meta
          name="keywords"
          content="Automation Solutions, Automation Company, Management, Meter, CCTV Solutions,
Intercom Service, Smart CCTV Solutions In Delhi NCR, Automatic Boom Barrier Solutions, Video Door
Phone System, Home Automation Company In Delhi NCR, FTTH, FTTX, GPON, Fire Alarm Company In
Delhi NCR, Fire Alarm System, Intercom Service Provider In Delhi NCR"
        />
        <meta
          name="description"
          content=" Join Sar Televenture as an investor and partner in advancing telecom infrastructure,
with innovative solutions that promise growth and success."
        />
        <meta
          name="title"
          content=" SAR Televenture: Investor Relations  Growth Opportunities"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://sarteleventure.com/" />
        <meta
          property="og:image"
          content="https://sarteleventure.com/path_to_image.jpg"
        />
        <meta
          property="og:description"
          content="SAR Televenture offers advanced FTTH solutions, providing high-speed and reliable fiber optic technology for modern telecommunications."
        />
        <meta property="og:site_name" content="SAR Televenture" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:updated_time" content="2024-04-19T00:00:00Z" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="https://sarteleventure.com/" />
        <meta
          name="twitter:description"
          content="SAR Televenture is the top fire alarm system and fire detection system company - designing and offering a variety of fire alarm systems.."
        />
        <meta
          name="twitter:image"
          content="https://sarteleventure.com/assets/imagenew/para-logo.png"
        />
      </Helmet>

      <PagetopCompontswithbg
        img={img}
        Title="Investor Desk"
        titleColor="black"
      />

      <div className="faq-area pt-100">
        <div className="container">
          <div className="row">
            {loading ? (
              <div className="preloader">
                <div className="d-table">
                  <div className="d-table-cell">
                    <div className="spinner"></div>
                  </div>
                </div>
              </div>
            ) : (
              data.map((item, index) => (
                <div className="col-lg-6" key={index}>
                  <div className="faq-content">
                    <div className="faq-accordion">
                      <ul className="accordion">
                        <li className="accordion-item">
                          <Link
                            className={`accordion-title ${
                              activeIndex === index ? "active" : ""
                            }`}
                            to="#"
                            onClick={() => toggleAccordion(index)}
                          >
                            <i
                              className={`bx ${
                                activeIndex === index ? "bx-minus" : "bx-plus"
                              }`}
                            ></i>
                            {item.title}
                          </Link>
                          <div
                            className={`accordion-content ${
                              activeIndex === index ? "show" : ""
                            }`}
                          >
                            <ul>
                              {item.documents_list.map((doc, docIndex) => (
                                <li
                                  className="d-flex justify-evenly gap-4"
                                  key={docIndex}
                                >
                                  <Link
                                    target="_blank"
                                    to={`${documentLink}/${doc.image}`}
                                    className="d-flex align-items-center gap-2 text-xl"
                                  >
                                    <FaFilePdf className="mt-2 font-bold text-xl" />
                                    <span className="text-xl mt-1 font-bold text-red-300">
                                      {doc.designation}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Investor;
