import React from "react";
import { HiBellAlert } from "react-icons/hi2";
import { MdSchedule } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";


import benifitimg from "../../assets/imagenew/Benefits.jpg";
import videoimg from "../../assets/imagenew/about-img1.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import OurServices from "../OurServices";
function Lucknow() {
  return (
    <>
     <Helmet>
        <meta
          name="title"
          content="Best Tower Company in Noida | Small Cell | Sar Televenture"
        />

        <meta
          name="description"
          content="Sar Televenture, a top telecom tower infrastructure company in Lucknow, offers FTTH, tower infra, home automation, fire alarms, and smart CCTV solutions."/>

        <meta
          name="keywords"
          content="tower infrastructure company in Lucknow,tower infrastructure company in Lucknow,tower company in Lucknow,telecom tower infrastructure company in Lucknow,cell tower infrastructure companies in Lucknow,infra tower company  in Lucknow,Ftth infrastructure company in Lucknow,Best ftth infrastructure company in Lucknow,Small Cell Infrastructure in Lucknow,Small cell infrastructure company in Lucknow,Intercom services in Lucknow,Intercom service provider in Lucknow,tower installation company in Lucknow,all Lucknow tower company,Lucknow tower company,top tower company in Lucknow,Tower Installation Service in Lucknow,tower infrastructure company in Lucknow,Best tower company in Lucknow,telecom tower infrastructure company in in Lucknow,telecom tower infrastructure company in Lucknow,cell tower infrastructure companies in Lucknow,infra tower company in Lucknow,Ftth infrastructure company in Lucknow,Small Cell Infrastructure in Lucknow,Intercom services in Lucknow,Intercom service provider in Lucknow,Energy Management Solutions in Lucknow,Fire Alarm Solution in Lucknow,Fire Alarm System in Lucknow,Fire Detection And Fire Alarm System in Lucknow,Fire Monitoring System in Lucknow,Home Automation And Security System in Lucknow,Home Automation Company in Lucknow,Home Automation Solutions in Lucknow,Prepaid Energy Meter in Lucknow,Security Camera Company in Lucknow,Security Solution in Lucknow,Smart CCTV Solutions in Lucknow,Smart Home Automation in Lucknow,Smart home solutions in Lucknow,Smart Home Systems in Lucknow,Smart Prepaid Meter in Lucknow,Intercom Service Provider in Lucknow,smart automation in Lucknow,home automation iot in Lucknow,smart home automation in Lucknow,home domotics in Lucknow,Access Control Systems in Lucknow,Boom Barrier Integrated Solutions in Lucknow,CCTV Service in Lucknow"/>
      </Helmet>
   <div
        className="inner-banner"
        style={{
          backgroundImage: `url(https://images.squarespace-cdn.com/content/v1/587e1dd33a04118eabe07a9d/1485147131500-98TSRSF5EH877OSFBIOP/telecom_banner.jpg?format=2500w)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="inner-title">
            <h1
              className=" sm:font-bold md:font-bold  sm:text-[90px]  md:text-[90px]  "
            style={{color:"Black"}}
            >
            </h1>
            <ul>
              <li
                className="  font-bold text-[68px] "
                style={{color:"Black"}}
              >
              </li>
            </ul>
          </div>
        </div>
      </div>
    <div>
      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-play">
                <img src={videoimg} alt="About-Images" />
                <div className="about-play-content">
                  <h1 className="d-none">Smart Home Solution </h1>
                  <span>Watch Our Intro Video</span>
                  <h2>Perfect Solution for Home Automation !</h2>
                  <div className="play-on-area">
                    <Link to="#" className="play-on popup-btn">
                      <i className="bx bx-play"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content ml-25">
                <div className="section-title">
                
                  <h2>Accelerate Your Business Growth with Our Innovative Solutions</h2>
                  
                  <p>As leaders in Lucknow, we specialize in the design, deployment, integration, and management of state-of-the-art communication networks. Our all-encompassing platform provides high-performance fiber systems, ensuring durable and scalable solutions. By partnering with India’s foremost developers, we facilitate the creation of future-ready homes equipped with premium digital services, including IP and IoT technologies, sophisticated security systems, energy management, and smart home automation—tailored to meet specific needs and enhance contemporary living experiences.</p>
                </div>
                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="choose-area pt-100 pb-70  "
      style={{ backgroundColor: "gray" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="mr-auto place-self-center lg:col-span-7">
             
              <h4 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
              Advanced Infrastructure and Security Solutions in Lucknow
              </h4>
              <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0 ">
                <ul className="list-disc text-white">
                  <ul>As a leading telecom tower infrastructure company in Lucknow, we provide comprehensive solutions for modern connectivity and security needs. Our services include tower infrastructure, FTTH infrastructure, and small cell solutions to ensure robust communication networks with high-speed fiber systems. We also specialize in advanced security services, offering video door phones, CCTV systems, access control, and boom barriers to enhance safety and security for residential and commercial properties. Additionally, our home automation services integrate smart technologies for energy management and seamless control, creating efficient, future-ready environments in Lucknow.
                  </ul>
                  
                 
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="choose-img">
              <img src={benifitimg} alt="Images" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <OurServices/>
    <section className="   border bg-[#D5E5F4] ">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className=" w-full mb-8 lg:mb-16 flex justify-center items-center flex-col">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-[#0071DC] ">
            Features
          </h2>
          <p className="text-black sm:text-xl ">
            Wire-free Control | Real-time Alerts | Easy Scheduling
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 ">
          <div className="  ">
            <CiMobile3 className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC]  mt-4">
              App Control
            </h3>
            <p className="text-black ">
              Control the entire ecosystem of your smart home from anywhere,
              anytime with our user-friendly phone app with a single touch.
            </p>
          </div>
          <div>
            <HiBellAlert className="text-[#0071DC] text-4xl " />

            <h3 className="mb-2 text-xl font-bold text-[#0071DC]  mt-4">
              Alert
            </h3>
            <p className="text-black ">
              Get live security alerts directly sent to your phone & keep
              track of all that’s happening at your home. Stay stress-free on
              vacations with your home’s security ensured.
            </p>
          </div>
          <div>
            <MdSchedule className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC] mt-4">
              Scheduling
            </h3>
            <p className="text-black ">
              Schedule the run-time of all the appliances at your smart home.
              Pre-set lights, fans, air-conditioner, geyser and television to
              turn ON/OFF at a specific time
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
  );
}

export default Lucknow;
