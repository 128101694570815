import React from "react";
import { HiBellAlert } from "react-icons/hi2";
import { MdSchedule } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";


import benifitimg from "../../assets/imagenew/Benefits.jpg";
import videoimg from "../../assets/imagenew/about-img1.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import OurServices from "../OurServices";
function Hyderabad() {
  return (
    <>
     <Helmet>
        <meta
          name="title"
          content="Small Cell Infrastructure in Hyderabad | Intercom | Sar Televenture"
        />

        <meta
          name="description"
          content="Sar Televenture specializes in small cell infrastructure in Hyderabad, offering FTTH, tower infrastructure, home automation, and fire alarm solutions."
        />

        <meta
          name="keywords"
          content="tower infrastructure company in Hyderabad,tower infrastructure company in Hyderabad,tower company in Hyderabad,telecom tower infrastructure company in Hyderabad,cell tower infrastructure companies in Hyderabad,infra tower company  in Hyderabad,Ftth infrastructure company in Hyderabad,Best ftth infrastructure company in Hyderabad,Small Cell Infrastructure in Hyderabad,Small cell infrastructure company in Hyderabad,Intercom services in Hyderabad,Intercom service provider in Hyderabad,tower installation company in Hyderabad,all Hyderabad tower company,Hyderabad tower company,top tower company in Hyderabad,Tower Installation Service in Hyderabad,tower infrastructure company in Hyderabad,Best tower company in Hyderabad,telecom tower infrastructure company in in Hyderabad,telecom tower infrastructure company in Hyderabad,cell tower infrastructure companies in Hyderabad,infra tower company in Hyderabad,Ftth infrastructure company in Hyderabad,Small Cell Infrastructure in Hyderabad,Intercom services in Hyderabad,Intercom service provider in Hyderabad,Energy Management Solutions in Hyderabad,Fire Alarm Solution in Hyderabad,Fire Alarm System in Hyderabad,Fire Detection And Fire Alarm System in Hyderabad,Fire Monitoring System in Hyderabad,Home Automation And Security System in Hyderabad,Home Automation Company in Hyderabad,Home Automation Solutions in Hyderabad,Prepaid Energy Meter in Hyderabad,Security Camera Company in Hyderabad,Security Solution in Hyderabad,Smart CCTV Solutions in Hyderabad,Smart Home Automation in Hyderabad,Smart home solutions in Hyderabad,Smart Home Systems in Hyderabad,Smart Prepaid Meter in Hyderabad,Intercom Service Provider in Hyderabad,smart automation in Hyderabad,home automation iot in Hyderabad,smart home automation in Hyderabad,home domotics in Hyderabad,Access Control Systems in Hyderabad,Boom Barrier Integrated Solutions in Hyderabad,CCTV Service in Hyderabad"
        />
      </Helmet>
   <div
        className="inner-banner"
        style={{
          backgroundImage: `url(https://images.squarespace-cdn.com/content/v1/587e1dd33a04118eabe07a9d/1485147131500-98TSRSF5EH877OSFBIOP/telecom_banner.jpg?format=2500w)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="inner-title">
            <h1
              className=" sm:font-bold md:font-bold  sm:text-[90px]  md:text-[90px]  "
            style={{color:"Black"}}
            >
            </h1>
            <ul>
              <li
                className="  font-bold text-[68px] "
                style={{color:"Black"}}
              >
              </li>
            </ul>
          </div>
        </div>
      </div>
    <div>
      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-play">
                <img src={videoimg} alt="About-Images" />
                <div className="about-play-content">
                  <h1 className="d-none">Smart Home Solution </h1>
                  <span>Watch Our Intro Video</span>
                  <h2>Perfect Solution for Home Automation !</h2>
                  <div className="play-on-area">
                    <Link to="#" className="play-on popup-btn">
                      <i className="bx bx-play"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content ml-25">
                <div className="section-title">
                
                  <h2>Accelerate Your Business Growth with Our Innovative Solutions
                  </h2>
                  
                  <p>As leaders in Hyderabad, we focus on the design, deployment, integration, and management of sophisticated communication networks. Our comprehensive platform delivers high-performance fiber systems, ensuring long-lasting and scalable solutions. By collaborating with India’s top developers, we support the development of future-ready homes that feature cutting-edge digital services, including IP and IoT technologies, advanced security systems, energy management, and smart home automation—customized to fulfill specific needs and elevate modern living experiences.
                  </p>
                </div>
                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="choose-area pt-100 pb-70  "
      style={{ backgroundColor: "gray" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="mr-auto place-self-center lg:col-span-7">
             
              <h4 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
              Comprehensive Infrastructure and Security Solutions in Hyderabad
              </h4>
              <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0 ">
                <ul className="list-disc text-white">
                  <ul>We provide a comprehensive range of infrastructure and security services in Hyderabad, designed to enhance both residential and commercial environments. Specializing in tower infrastructure and telecom tower infrastructure, we ensure robust communication networks that deliver reliable connectivity. Our FTTH infrastructure solutions offer high-speed fiber optic internet access, enabling seamless digital experiences for homes and businesses. To optimize network performance, we offer small cell infrastructure solutions that enhance wireless coverage and capacity. Security is a top priority; our advanced video door phone systems, CCTV services, and access control technologies keep your property well-protected. Additionally, our home automation services integrate cutting-edge technology for efficiency and convenience, while our boom barrier systems effectively manage access points. As a trusted provider of small cell infrastructure in Hyderabad, we are dedicated to delivering tailored solutions that meet the unique needs of our clients, helping to create safe, efficient, and technologically advanced environments throughout the city.

                  </ul>
                  
                 
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="choose-img">
              <img src={benifitimg} alt="Images" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <OurServices/>
    <section className="   border bg-[#D5E5F4] ">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className=" w-full mb-8 lg:mb-16 flex justify-center items-center flex-col">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-[#0071DC] ">
            Features
          </h2>
          <p className="text-black sm:text-xl ">
            Wire-free Control | Real-time Alerts | Easy Scheduling
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 ">
          <div className="  ">
            <CiMobile3 className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC]  mt-4">
              App Control
            </h3>
            <p className="text-black ">
              Control the entire ecosystem of your smart home from anywhere,
              anytime with our user-friendly phone app with a single touch.
            </p>
          </div>
          <div>
            <HiBellAlert className="text-[#0071DC] text-4xl " />

            <h3 className="mb-2 text-xl font-bold text-[#0071DC]  mt-4">
              Alert
            </h3>
            <p className="text-black ">
              Get live security alerts directly sent to your phone & keep
              track of all that’s happening at your home. Stay stress-free on
              vacations with your home’s security ensured.
            </p>
          </div>
          <div>
            <MdSchedule className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC] mt-4">
              Scheduling
            </h3>
            <p className="text-black ">
              Schedule the run-time of all the appliances at your smart home.
              Pre-set lights, fans, air-conditioner, geyser and television to
              turn ON/OFF at a specific time
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
  );
}

export default Hyderabad;
