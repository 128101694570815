import React from "react";
import { HiBellAlert } from "react-icons/hi2";
import { MdSchedule } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";


import benifitimg from "../../assets/imagenew/Benefits.jpg";
import videoimg from "../../assets/imagenew/about-img1.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import OurServices from "../OurServices";
function Noida() {
  return (
    <>
     <Helmet>
        <meta
          name="title"
          content="Tower Infrastructure Company in Noida | Small Cell | Sar Televenture"
        />

        <meta
          name="description"
          content="Sar Televenture, a top tower infrastructure company in Noida, specializes in small cell solutions and FTTH infrastructure for seamless connectivity and performance."
        />

        <meta
          name="keywords"
          content="tower infrastructure company in noida,tower infrastructure company in noida,tower company in noida,telecom tower infrastructure company in noida,cell tower infrastructure companies in noida,infra tower company  in noida,Ftth infrastructure company in noida,Best ftth infrastructure company in noida,Small Cell Infrastructure in noida,Small cell infrastructure company in noida,Intercom services in noida,Intercom service provider in noida,tower installation company in noida,all noida tower company,Noida tower company,top tower company in noida,Tower Installation Service in noida,tower infrastructure company in noida,Best tower company in Noida,telecom tower infrastructure company in in noida,telecom tower infrastructure company in noida,cell tower infrastructure companies in noida,infra tower company in noida,Ftth infrastructure company in noida,Small Cell Infrastructure in noida,Intercom services in noida,Intercom service provider in noida,Energy Management Solutions in noida,Fire Alarm Solution in noida,Fire Alarm System in noida,Fire Detection And Fire Alarm System in noida,Fire Monitoring System in noida,Home Automation And Security System in noida,Home Automation Company in noida,Home Automation Solutions in noida,Prepaid Energy Meter in noida,Security Camera Company in noida,Security Solution in noida,Smart CCTV Solutions in noida,Smart Home Automation in noida,Smart home solutions in noida,Smart Home Systems in noida,Smart Prepaid Meter in noida,Intercom Service Provider in noida,smart automation in noida,home automation iot in noida,smart home automation in noida,home domotics in noida,Access Control Systems in noida,Boom Barrier Integrated Solutions in Noida,CCTV Service in noida"
        />
      </Helmet>
   <div
        className="inner-banner"
        style={{
          backgroundImage: `url(https://images.squarespace-cdn.com/content/v1/587e1dd33a04118eabe07a9d/1485147131500-98TSRSF5EH877OSFBIOP/telecom_banner.jpg?format=2500w)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="inner-title">
            <h1
              className=" sm:font-bold md:font-bold  sm:text-[90px]  md:text-[90px]  "
            style={{color:"Black"}}
            >
            </h1>
            <ul>
              <li
                className="  font-bold text-[68px] "
                style={{color:"Black"}}
              >
              </li>
            </ul>
          </div>
        </div>
      </div>
    <div>
      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-play">
                <img src={videoimg} alt="About-Images" />
                <div className="about-play-content">
                  <h1 className="d-none">Smart Home Solution </h1>
                  <span>Watch Our Intro Video</span>
                  <h2>Perfect Solution for Home Automation !</h2>
                  <div className="play-on-area">
                    <Link to="#" className="play-on popup-btn">
                      <i className="bx bx-play"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content ml-25">
                <div className="section-title">
                
                  <h2>
                  Boost Your Business with Our Solutions

                  </h2>
                  
                  <p>
                  As pioneers in Noida, we specialize in designing, deploying, integrating, and managing cutting-edge communication networks. Our all-in-one platform powers high-performance fiber systems, providing future-proof solutions. Partnering with India’s top developers, we help build homes equipped with advanced digital services, including IP and IoT systems, security, energy management, and home automation—tailored to meet unique needs.
                  </p>
                </div>
                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="choose-area pt-100 pb-70  "
      style={{ backgroundColor: "gray" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="mr-auto place-self-center lg:col-span-7">
             
              <h4 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
              Comprehensive Infrastructure and Automation Solutions in Noida
              </h4>
              <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0 ">
                <ul className="list-disc text-white">
                  <ul>We are dedicated to offering comprehensive infrastructure and automation solutions in Noida, aimed at enhancing both residential and commercial spaces. Our services range from telecom tower infrastructure and FTTH (Fiber to the Home) connectivity to small cell solutions that improve network coverage and capacity. We also provide advanced home automation systems, integrating comfort, security, and energy efficiency into daily living. Our offerings include modern video door phone systems for added security, CCTV services for round-the-clock surveillance, and access control systems to ensure secure entry. Additionally, we specialize in boom barrier solutions for efficient traffic management. With a focus on innovation and high-performance technology, we tailor our solutions to meet the evolving needs of our clients, ensuring seamless connectivity and security across all aspects of their spaces.
                  </ul>
                  
                 
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="choose-img">
              <img src={benifitimg} alt="Images" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <OurServices/>
    <section className="   border bg-[#D5E5F4] ">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className=" w-full mb-8 lg:mb-16 flex justify-center items-center flex-col">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-[#0071DC] ">
            Features
          </h2>
          <p className="text-black sm:text-xl ">
            Wire-free Control | Real-time Alerts | Easy Scheduling
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 ">
          <div className="  ">
            <CiMobile3 className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC]  mt-4">
              App Control
            </h3>
            <p className="text-black ">
              Control the entire ecosystem of your smart home from anywhere,
              anytime with our user-friendly phone app with a single touch.
            </p>
          </div>
          <div>
            <HiBellAlert className="text-[#0071DC] text-4xl " />

            <h3 className="mb-2 text-xl font-bold text-[#0071DC]  mt-4">
              Alert
            </h3>
            <p className="text-black ">
              Get live security alerts directly sent to your phone & keep
              track of all that’s happening at your home. Stay stress-free on
              vacations with your home’s security ensured.
            </p>
          </div>
          <div>
            <MdSchedule className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC] mt-4">
              Scheduling
            </h3>
            <p className="text-black ">
              Schedule the run-time of all the appliances at your smart home.
              Pre-set lights, fans, air-conditioner, geyser and television to
              turn ON/OFF at a specific time
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
  );
}

export default Noida;
