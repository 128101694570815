import React from "react";
import { HiBellAlert } from "react-icons/hi2";
import { MdSchedule } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";


import benifitimg from "../../assets/imagenew/Benefits.jpg";
import videoimg from "../../assets/imagenew/about-img1.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import OurServices from "../OurServices";
function Kanpur() {
  return (
    <>
     <Helmet>
        <meta
          name="title"
          content="Telecom Tower Infrastructure Company in Kanpur | Sar Televenture"
        />

        <meta
          name="description"
          content="Sar Televenture, a leading tower infrastructure company in Kanpur, offers FTTH infra, tower infra, home automation, fire alarms, and smart CCTV solutions."/>

        <meta
          name="keywords"
          content="tower infrastructure company in Kanpur,tower infrastructure company in Kanpur,tower company in Kanpur,telecom tower infrastructure company in Kanpur,cell tower infrastructure companies in Kanpur,infra tower company  in Kanpur,Ftth infrastructure company in Kanpur,Best ftth infrastructure company in Kanpur,Small Cell Infrastructure in Kanpur,Small cell infrastructure company in Kanpur,Intercom services in Kanpur,Intercom service provider in Kanpur,tower installation company in Kanpur,all Kanpur tower company,Kanpur tower company,top tower company in Kanpur,Tower Installation Service in Kanpur,tower infrastructure company in Kanpur,Best tower company in Kanpur,telecom tower infrastructure company in in Kanpur,telecom tower infrastructure company in Kanpur,cell tower infrastructure companies in Kanpur,infra tower company in Kanpur,Ftth infrastructure company in Kanpur,Small Cell Infrastructure in Kanpur,Intercom services in Kanpur,Intercom service provider in Kanpur,Energy Management Solutions in Kanpur,Fire Alarm Solution in Kanpur,Fire Alarm System in Kanpur,Fire Detection And Fire Alarm System in Kanpur,Fire Monitoring System in Kanpur,Home Automation And Security System in Kanpur,Home Automation Company in Kanpur,Home Automation Solutions in Kanpur,Prepaid Energy Meter in Kanpur,Security Camera Company in Kanpur,Security Solution in Kanpur,Smart CCTV Solutions in Kanpur,Smart Home Automation in Kanpur,Smart home solutions in Kanpur,Smart Home Systems in Kanpur,Smart Prepaid Meter in Kanpur,Intercom Service Provider in Kanpur,smart automation in Kanpur,home automation iot in Kanpur,smart home automation in Kanpur,home domotics in Kanpur,Access Control Systems in Kanpur,Boom Barrier Integrated Solutions in Kanpur,CCTV Service in Kanpur"/>
      </Helmet>
   <div
        className="inner-banner"
        style={{
          backgroundImage: `url(https://images.squarespace-cdn.com/content/v1/587e1dd33a04118eabe07a9d/1485147131500-98TSRSF5EH877OSFBIOP/telecom_banner.jpg?format=2500w)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="inner-title">
            <h1
              className=" sm:font-bold md:font-bold  sm:text-[90px]  md:text-[90px]  "
            style={{color:"Black"}}
            >
            </h1>
            <ul>
              <li
                className="  font-bold text-[68px] "
                style={{color:"Black"}}
              >
              </li>
            </ul>
          </div>
        </div>
      </div>
    <div>
      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-play">
                <img src={videoimg} alt="About-Images" />
                <div className="about-play-content">
                  <h1 className="d-none">Smart Home Solution </h1>
                  <span>Watch Our Intro Video</span>
                  <h2>Perfect Solution for Home Automation !</h2>
                  <div className="play-on-area">
                    <Link to="#" className="play-on popup-btn">
                      <i className="bx bx-play"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content ml-25">
                <div className="section-title">
                
                  <h2>Accelerate Your Business Growth with Our Advanced Solutions</h2>
                  
                  <p>As innovators in Kanpur, we excel in the design, deployment, integration, and management of cutting-edge communication networks. Our comprehensive platform offers high-performance fiber systems, delivering reliable and scalable solutions. By collaborating with India’s leading developers, we help build future-ready homes equipped with top-tier digital services, such as IP and IoT technologies, advanced security solutions, energy management systems, and smart home automation—customized to meet unique needs and elevate modern living standards.</p>
                </div>
                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="choose-area pt-100 pb-70  "
      style={{ backgroundColor: "gray" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="mr-auto place-self-center lg:col-span-7">
             
              <h4 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
              Advanced Telecom and Security Solutions in Kanpur
              </h4>
              <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0 ">
                <ul className="list-disc text-white">
                  <ul>As a leading tower infrastructure company in Kanpur, we provide comprehensive solutions for advanced connectivity and security. Our services include tower infrastructure, FTTH infrastructure, and telecom tower infrastructure, ensuring reliable, high-speed networks for modern communication needs. Additionally, we offer small cell solutions to enhance network coverage and performance. For security, we provide video door phone systems, CCTV services, access control, and boom barriers for both residential and commercial properties. Our home automation services bring smart technology to homes, offering seamless control over lighting, security, and energy management, creating efficient, future-ready spaces in Kanpur.
                  </ul>
                  
                 
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="choose-img">
              <img src={benifitimg} alt="Images" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <OurServices/>
    <section className="   border bg-[#D5E5F4] ">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className=" w-full mb-8 lg:mb-16 flex justify-center items-center flex-col">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-[#0071DC] ">
            Features
          </h2>
          <p className="text-black sm:text-xl ">
            Wire-free Control | Real-time Alerts | Easy Scheduling
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 ">
          <div className="  ">
            <CiMobile3 className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC]  mt-4">
              App Control
            </h3>
            <p className="text-black ">
              Control the entire ecosystem of your smart home from anywhere,
              anytime with our user-friendly phone app with a single touch.
            </p>
          </div>
          <div>
            <HiBellAlert className="text-[#0071DC] text-4xl " />

            <h3 className="mb-2 text-xl font-bold text-[#0071DC]  mt-4">
              Alert
            </h3>
            <p className="text-black ">
              Get live security alerts directly sent to your phone & keep
              track of all that’s happening at your home. Stay stress-free on
              vacations with your home’s security ensured.
            </p>
          </div>
          <div>
            <MdSchedule className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC] mt-4">
              Scheduling
            </h3>
            <p className="text-black ">
              Schedule the run-time of all the appliances at your smart home.
              Pre-set lights, fans, air-conditioner, geyser and television to
              turn ON/OFF at a specific time
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
  );
}

export default Kanpur;
