import React from "react";
import { HiBellAlert } from "react-icons/hi2";
import { MdSchedule } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";


import benifitimg from "../../assets/imagenew/Benefits.jpg";
import backgroundImage from "../../assets/imagenew/Smarthomebgimge.jpg";
import videoimg from "../../assets/imagenew/about-img1.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
function SmartCCTVsolutionsinNoida() {
  return (
    <>
     <Helmet>
        <meta
          name="title"
          content="Best Smart CCTV Solutions in Noida"
        />

        <meta
          name="description"
          content="Upgrade with Smart CCTV Solutions in Noida—advanced surveillance technology for clear, reliable monitoring and enhanced security"
        />

        <meta
          name="keywords"
          content="Smart CCTV Solutions in Noida"
        />
      </Helmet>
   <div
        className="inner-banner"
        style={{
          backgroundImage: `url(https://cdn.internationalemploymentlawyer.com/public/styles/5_4/public/2023-08/iStock-518799872.jpg?itok=ChYEwIPf)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="inner-title">
            <h1
              className=" sm:font-bold md:font-bold  sm:text-[90px]  md:text-[90px]  "
            style={{color:"white"}}
            >
              Smart CCTV solutions in Noida
            </h1>
            <ul>
              <li
                className="  font-bold text-[68px] "
            
              >
                Your Gateway to Secure Living
              </li>
            </ul>
          </div>
        </div>
      </div>
    <div>
      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-play">
                <img src={videoimg} alt="About-Images" />
                <div className="about-play-content">
                  <h1 className="d-none">Smart Home Solution </h1>
                  <span>Watch Our Intro Video</span>
                  <h2>Perfect Solution for Home Automation !</h2>
                  <div className="play-on-area">
                    <Link to="#" className="play-on popup-btn">
                      <i className="bx bx-play"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content ml-25">
                <div className="section-title">
                  <span className="sp-color2">Home Automation</span>
                  <h2>
                    SAR Televenture : Your One-Stop Solutions for Intelligent
                    Automation
                  </h2>
                  <h6 className=" pt-2">
                    Transform your space with SAR Televenture Tailored Home
                    Automation Solutions!
                  </h6>
                  <p>
                    We offer comprehensive automation systems designed to
                    elevate convenience, security, and energy efficiency
                    across diverse applications
                  </p>
                  <p>
                    <span>Homes</span> Experience a smarter lifestyle with
                    effortless control over lighting, thermostats, appliances,
                    and more.
                  </p>
                  <p>
                    <span>Commercial Buildings</span> Enhance operational
                    efficiency, optimize energy use, and create a secure
                    environment for your business.
                  </p>
                  <p>
                    <span> Hotels</span> Deliver an exceptional guest
                    experience with features like in-room temperature control
                    and personalized lighting.
                  </p>
                  <p>
                    <span> Public Utility Sector</span> Leverage our
                    automation expertise to optimize resource management and
                    improve service delivery.
                  </p>
                </div>
                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="choose-area pt-100 pb-70  "
      style={{ backgroundColor: "gray" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="mr-auto place-self-center lg:col-span-7">
              <h1 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
              Use cutting-edge Smart CCTV Solutions in Noida to Revolutionize Security 
              </h1>
              <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0 ">
                <ul className="list-disc text-white">
                 <li>Upgrade your security approach with Sar Televenture's smart CCTV solutions in Noida, a thriving metropolis. Our smart CCTV systems are made to satisfy the changing demands of urban settings. They combine cutting-edge technology with user-friendly features to offer reliable surveillance and peace of mind.
                 </li>
                 <li>The core products of Sar Televenture are high-definition cameras, which record crisp, detailed images day or night. Whether you're securing a Noida residential neighborhood, business building, or industrial location, our systems provide thorough coverage and efficient monitoring. Real-time threat alerts from sophisticated features like motion detection and facial recognition improve security.
                 </li>
                 <li>We are aware that every Noida location faces different security difficulties. For this reason, Sar Televenture offers CCTV solutions that may be tailored to meet your unique needs. Whether you require outdoor monitoring, indoor surveillance, or a combination of the two, our scalable systems may be easily customized to match your requirements.
                 </li>
                 <li>At Sar Televenture, usability is of the utmost importance. With our intuitive user interfaces and mobile application, you can remotely monitor your property from any location in the globe. With immediate access to real-time video feeds and notifications on your smartphone, you can always stay informed and in charge of your security.
                 </li>
                 <li>Dependability and client satisfaction are Sar Televenture's top priorities as your go-to security partner. Our committed support staff is always here to help, making sure your CCTV systems run smoothly and have the highest possible uptime.
                 </li>
                 <li>With the smart CCTV systems in Noida from Sar Televenture, discover the security of the future. Get in touch with us right now to find out more about how our specialized security solutions can improve your safety.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="choose-img">
              <img src={benifitimg} alt="Images" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className={`security-area pt-100 pb-70 security-area pt-100 pb-70 bg-cover bg-cente  opacity-80`}
      style={{
        backgroundImage: `url(${backgroundImage})`, // Use the imported image
      }}
    >
      <div className="absolute inset-0  opacity-80"></div>
      <div className="container   z-10">
        <div className="section-title text-center z-10 flex  center flex-col items-center lg:ml-[250px]">
          <span className="sp-color4  text-white">Our Process</span>
          <h2 className="leading-3 text-white " style={{ fontSize: "25px" }}>
            We follow an automation process that is flexible, easy to
            personalize & execute.
          </h2>
        </div>
        <div className="row pt-45">
          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-cyber-security"></i>
              <h3>
                <Link to="#">Planning</Link>
              </h3>
              <p>
                Our team consults you on the project at an early planning
                stage and ensures seamless integration of all the components
                of your home.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-implement"></i>
              <h3>
                <Link to="#">Hand-Over</Link>
              </h3>
              <p>
                At the time of hand over, our team thoroughly demonstrates all
                of the smart functionalities of your digital home. tortor
                odio.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-consulting"></i>
              <h3>
                <Link to="#">Servicing</Link>
              </h3>
              <p>
                We provide an AMC contract for timely servicing of the
                automation modules. Our team of experts conducts a complete
                servicing of entire complex.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-computer"></i>
              <h3>
                <Link to="#">Model Home</Link>
              </h3>
              <p>
                We help you set up your model home & personalize it as per the
                requirements of your modern lifestyle.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-effective"></i>
              <h3>
                <Link to="#">Execution</Link>
              </h3>
              <p>
                Once all the structural requirements are met, our team comes
                in for a complete automation installation.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="security-card">
              <i className="flaticon-consultant"></i>
              <h3>
                <Link to="#">Shifting</Link>
              </h3>
              <p>
                Our wire-free automation modules are easy to disconnect &
                re-install at your next home
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section className="   border bg-[#D5E5F4] ">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className=" w-full mb-8 lg:mb-16 flex justify-center items-center flex-col">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-[#0071DC] ">
            Features
          </h2>
          <p className="text-black sm:text-xl ">
            Wire-free Control | Real-time Alerts | Easy Scheduling
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 ">
          <div className="  ">
            <CiMobile3 className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC]  mt-4">
              App Control
            </h3>
            <p className="text-black ">
              Control the entire ecosystem of your smart home from anywhere,
              anytime with our user-friendly phone app with a single touch.
            </p>
          </div>
          <div>
            <HiBellAlert className="text-[#0071DC] text-4xl " />

            <h3 className="mb-2 text-xl font-bold text-[#0071DC]  mt-4">
              Alert
            </h3>
            <p className="text-black ">
              Get live security alerts directly sent to your phone & keep
              track of all that’s happening at your home. Stay stress-free on
              vacations with your home’s security ensured.
            </p>
          </div>
          <div>
            <MdSchedule className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC] mt-4">
              Scheduling
            </h3>
            <p className="text-black ">
              Schedule the run-time of all the appliances at your smart home.
              Pre-set lights, fans, air-conditioner, geyser and television to
              turn ON/OFF at a specific time
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
  );
}

export default SmartCCTVsolutionsinNoida;
