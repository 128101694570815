import React from "react";
import { HiBellAlert } from "react-icons/hi2";
import { MdSchedule } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";


import benifitimg from "../../assets/imagenew/Benefits.jpg";
import videoimg from "../../assets/imagenew/about-img1.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import OurServices from "../OurServices";
function Mohali() {
  return (
    <>
     <Helmet>
        <meta
          name="title"
          content="Ftth Infrastructure Company in Mohali I Small Cell |Sar Televenture"
        />

        <meta
          name="description"
          content="Sar Televenture is a small cell infrastructure company in Mohali, offering FTTH, tower infrastructure, home automation, and fire alarm solutions."/>

        <meta
          name="keywords"
          content="tower infrastructure company in Mohali,tower infrastructure company in Mohali,tower company in Mohali,telecom tower infrastructure company in Mohali,cell tower infrastructure companies in Mohali,infra tower company  in Mohali,Ftth infrastructure company in Mohali,Best ftth infrastructure company in Mohali,Small Cell Infrastructure in Mohali,Small cell infrastructure company in Mohali,Intercom services in Mohali,Intercom service provider in Mohali,tower installation company in Mohali,all Mohali tower company,Mohali tower company,top tower company in Mohali,Tower Installation Service in Mohali,tower infrastructure company in Mohali,Best tower company in Mohali,telecom tower infrastructure company in in Mohali,telecom tower infrastructure company in Mohali,cell tower infrastructure companies in Mohali,infra tower company in Mohali,Ftth infrastructure company in Mohali,Small Cell Infrastructure in Mohali,Intercom services in Mohali,Intercom service provider in Mohali,Energy Management Solutions in Mohali,Fire Alarm Solution in Mohali,Fire Alarm System in Mohali,Fire Detection And Fire Alarm System in Mohali,Fire Monitoring System in Mohali,Home Automation And Security System in Mohali,Home Automation Company in Mohali,Home Automation Solutions in Mohali,Prepaid Energy Meter in Mohali,Security Camera Company in Mohali,Security Solution in Mohali,Smart CCTV Solutions in Mohali,Smart Home Automation in Mohali,Smart home solutions in Mohali,Smart Home Systems in Mohali,Smart Prepaid Meter in Mohali,Intercom Service Provider in Mohali,smart automation in Mohali,home automation iot in Mohali,smart home automation in Mohali,home domotics in Mohali,Access Control Systems in Mohali,Boom Barrier Integrated Solutions in Mohali,CCTV Service in Mohali"/>
      </Helmet>
   <div
        className="inner-banner"
        style={{
          backgroundImage: `url(https://images.squarespace-cdn.com/content/v1/587e1dd33a04118eabe07a9d/1485147131500-98TSRSF5EH877OSFBIOP/telecom_banner.jpg?format=2500w)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="inner-title">
            <h1
              className=" sm:font-bold md:font-bold  sm:text-[90px]  md:text-[90px]  "
            style={{color:"Black"}}
            >
            </h1>
            <ul>
              <li
                className="  font-bold text-[68px] "
                style={{color:"Black"}}
              >
              </li>
            </ul>
          </div>
        </div>
      </div>
    <div>
      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-play">
                <img src={videoimg} alt="About-Images" />
                <div className="about-play-content">
                  <h1 className="d-none">Smart Home Solution </h1>
                  <span>Watch Our Intro Video</span>
                  <h2>Perfect Solution for Home Automation !</h2>
                  <div className="play-on-area">
                    <Link to="#" className="play-on popup-btn">
                      <i className="bx bx-play"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content ml-25">
                <div className="section-title">
                
                  <h2>Accelerate Your Business Growth with Our Advanced Solutions
                  </h2>
                  
                  <p>As industry leaders in Mohali, we specialize in the design, deployment, integration, and management of advanced communication networks. Our all-inclusive platform provides high-performance fiber systems, guaranteeing durable and scalable solutions. By partnering with India’s leading developers, we facilitate the creation of future-ready homes equipped with state-of-the-art digital services, including IP and IoT technologies, robust security systems, energy management, and smart home automation—tailored to meet individual requirements and enhance contemporary living experiences.
                  </p>
                </div>
                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="choose-area pt-100 pb-70  "
      style={{ backgroundColor: "gray" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="mr-auto place-self-center lg:col-span-7">
             
              <h4 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
              Comprehensive Infrastructure and Security Solutions in Mohali
              </h4>
              <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0 ">
                <ul className="list-disc text-white">
                  <ul>We are a leading provider of infrastructure and security services in Mohali, dedicated to enhancing both residential and commercial environments. Specializing in tower infrastructure and telecom tower infrastructure, we ensure reliable communication networks that meet the demands of modern connectivity. Our FTTH infrastructure solutions deliver high-speed fiber optics for seamless internet access, while our small cell infrastructure solutions improve wireless coverage and capacity. Security is a top priority; our advanced video door phone systems, CCTV services, and access control technologies provide comprehensive protection for your property. Additionally, our home automation services integrate innovative technology for enhanced efficiency and convenience, and our boom barrier systems effectively manage access at entry points. As a trusted small cell infrastructure company in Mohali, we are committed to delivering tailored solutions that address the unique needs of our clients, helping to create safe, efficient, and technologically advanced environments throughout the city.

                  </ul>
                  
                 
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="choose-img">
              <img src={benifitimg} alt="Images" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <OurServices/>
    <section className="   border bg-[#D5E5F4] ">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className=" w-full mb-8 lg:mb-16 flex justify-center items-center flex-col">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-[#0071DC] ">
            Features
          </h2>
          <p className="text-black sm:text-xl ">
            Wire-free Control | Real-time Alerts | Easy Scheduling
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 ">
          <div className="  ">
            <CiMobile3 className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC]  mt-4">
              App Control
            </h3>
            <p className="text-black ">
              Control the entire ecosystem of your smart home from anywhere,
              anytime with our user-friendly phone app with a single touch.
            </p>
          </div>
          <div>
            <HiBellAlert className="text-[#0071DC] text-4xl " />

            <h3 className="mb-2 text-xl font-bold text-[#0071DC]  mt-4">
              Alert
            </h3>
            <p className="text-black ">
              Get live security alerts directly sent to your phone & keep
              track of all that’s happening at your home. Stay stress-free on
              vacations with your home’s security ensured.
            </p>
          </div>
          <div>
            <MdSchedule className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC] mt-4">
              Scheduling
            </h3>
            <p className="text-black ">
              Schedule the run-time of all the appliances at your smart home.
              Pre-set lights, fans, air-conditioner, geyser and television to
              turn ON/OFF at a specific time
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
  );
}

export default Mohali;
