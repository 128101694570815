import React from "react";

import { MdPermDeviceInformation } from "react-icons/md";
import { FaCoins } from "react-icons/fa6";
import { RiBatterySaverLine } from "react-icons/ri";
import smartmeterimg from "../../assets/imagenew/smartprepaidimg.jpeg";
import { Helmet } from "react-helmet";







function SmartPrepaidMeter() {
  return (
    <>
    <Helmet>
        <meta
          name="title"
          content="Smart Prepaid Meter | Best Smart Prepaid Meter"
        />

        <meta
          name="description"
          content="Discover efficient energy management with our Smart Prepaid Meter—accurate billing and real-time monitoring for a smarter home"
        />

        <meta
          name="keywords"
          content="Smart Prepaid Meter"
        />
      </Helmet>
    <div
        className="inner-banner"
        style={{
          backgroundImage: `url(http://localhost:3000/static/media/ftthfirstimg.f2741f490a58de090731.jpg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="inner-title">
            <h1
              className=" sm:font-bold md:font-bold  sm:text-[90px]  md:text-[90px]  "
            style={{color:"white"}}
            >
              Smart Prepaid Meter 
            </h1>
            <ul>
              <li
                className="  font-bold text-[68px] "
            
              >
                Empowering You to Control Your Energy Budget!
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className=" flex justify-center flex-col 
       mx-auto items-center lg:p-16  ml-16   w-[80%]  "
      >
        <h3 className=" lg:p16  flex  justify-center items-center w-full h-auto">
          Building a Smarter Grid: Powering India with Smart Meters
        </h3>
        <p className=" flex justify-center items-center  w-[80%] ">
          SAR Televenture Smart meters track your energy use in real time,
          helping you save money and be eco-friendly. They also eliminate manual
          meter readings, saving power companies money and improving service.
          Plus, you get low-balance alerts to avoid outages. This nationwide
          switch is transforming India's power grid! Contact your local
          electricity provider to see if smart meters are available in your area
          and join the smart energy revolution
        </p>
        <span></span>
      </div>
      <div
        id="services"
        className={`section relative pt-20 pb-8  h-auto md:pt-16   `}
        style={{
          backgroundImage: "linear-gradient(to right, #0D1023, #434A5D)",
        }}
      >
        <div className="container xl:max-w-6xl mx-auto px-4 ">
          <h4 className=" w-full h-auto flex justify-center items-center  mt-4 text-white">
            SAR Televenture prepaid meter benefits
          </h4>

          <div className="flex flex-wrap flex-row -mx-4 text-center  m-[-40px] ">
            <div
              className="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp animate-fadeInUp duration-1000 visible p-24"
              data-wow-duration="1s"
            >
              <div className="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div className="inline-block text-gray-900 mb-4 h-[60px]">
                  <FaCoins className=" text-4xl text-[#0071DC] " />
                </div>
                <h3 className="text-lg leading-normal mb-2 font-semibold text-black">
                  Save Money & Energy
                </h3>
                <p className="text-gray-500 text-sm">
                  with SAR Televenture smart prepaid meter See your usage in
                  real-time and make adjustments to lower bills and conserve
                  resources.
                </p>
              </div>
            </div>
            <div
              className="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp  animate-fadeInUp duration-1000 visible p-24"
              data-wow-duration="1s"
              data-wow-delay=".1s"
              // style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s; animation-name: fadeInUp;"
            >
              <div className="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div className="inline-block text-gray-900 mb-4 h-[60px]">
                  {/* <SiBoost className=" text-4xl" /> */}
                  <RiBatterySaverLine className=" text-4xl" />
                </div>
                <h3 className="text-lg leading-normal mb-2 font-semibold text-black">
                  Boost Efficiency
                </h3>
                <p className="text-gray-500 text-sm">
                  Eliminate manual meter reading, reduce losses for power
                  companies, and improve overall supply <br /> <br />
                </p>
              </div>
            </div>
            <div
              className="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp p-24"
              data-wow-duration="1s"
              data-wow-delay=".3s"
              // style="visibility: visible; animation-duration: 1s; animation-delay: 0.3s; animation-name: fadeInUp;"
            >
              <div className="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div className="inline-block text-gray-900 mb-4 h-[82px]">
                  <MdPermDeviceInformation className=" text-4xl" />
                </div>
                <h3 className="text-lg leading-normal mb-2 font-semibold text-black">
                  Stay Informed
                </h3>
                <p className="text-gray-500 text-sm ">
                  Get low-balance alerts to avoid disruptions and manage your
                  usage proactively. <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white ">
        <h4 className=" w-full h-auto flex justify-center items-center  mt-16">
        Smart Prepaid Meter
        </h4>
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-1 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
           
            <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0  ">
              <ul className="list-disc">
                <li>
                Welcome to a new era of energy management with Sar Smart Prepaid Meter. Our advanced technology simplifies how you control and monitor your energy consumption. Imagine having the ability to manage your energy use effortlessly from your smartphone. Our Smart Prepaid Meter provides real-time access to your consumption data, allowing you to track usage, adjust settings, and load credits with ease. This prepaid system empowers you to stay on top of your energy expenses, avoiding unexpected costs and helping you stay within your budget. Designed for seamless integration, our meter fits effortlessly into your existing setup, offering a modern upgrade that enhances your energy management without requiring extensive changes. Enjoy the benefits of accurate billing, reduced administrative tasks, and a clear understanding of your energy habits. With Sar’s Smart Prepaid Meter, you get a practical solution that not only simplifies billing but also encourages mindful energy use. Experience greater control and transparency with a system that makes managing your energy needs straightforward and intuitive. At Sar, we are committed to providing innovative solutions that improve your energy experience. Discover how our Smart Prepaid Meter can bring efficiency and clarity to your home or business. Contact us today to learn more about how this cutting-edge technology can transform your approach to energy management. Embrace smarter, more efficient energy use with Sar.
                </li>
              </ul>
            </div>
          </div>

          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src={smartmeterimg} alt="smartmeterimg" />
          </div>
        </div>
      </section>
    </>
  );
}

export default SmartPrepaidMeter;
