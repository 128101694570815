import React from "react";

import designe from "../../assets/imagenew/1.png";

import fttmanimg from "../../assets/imagenew/ftthfirstimg.jpg";

import gif from "../../assets/imagenew/ADVANTAGES-OF-FTTH.gif";

import FTTHIMGE2 from "../../assets/imagenew/FTTHIMGE2.png";

import { Container, Row, Col } from "react-bootstrap";

import networkimg from "../../assets/imagenew/networkandbenifit.jpg";


import ftthbuilding from "../../assets/imagenew/ftth copy.jpg";
import { Helmet } from "react-helmet";
function InterServicesProviderInNoida() {
  return (
    <>
    <Helmet>
        <meta
          name="title"
          content="Best Intercom Service Provider in Noida"
        />

        <meta
          name="description"
          content="Choose the top Intercom Service Provider in Noida—reliable solutions, exceptional service, and seamless communication for your needs"
        />

        <meta
          name="keywords"
          content="Intercom Service Provider in Noida"
        />
      </Helmet>
    <div
        className="inner-banner"
        style={{
          backgroundImage: `url(http://localhost:3000/static/media/ftthfirstimg.f2741f490a58de090731.jpg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="inner-title">
            <h1
              className=" sm:font-bold md:font-bold  sm:text-[90px]  md:text-[90px]  "
            style={{color:"white"}}
            >
              Intercom Service Provider
            </h1>
            <ul>
              <li
                className="  font-bold text-[68px] "
            
              >
                Your Gateway to Secure Living
              </li>
            </ul>
          </div>
        </div>
      </div>
      <section
        className="md:pt-[112px] pt-16 relative bg-gray-300 pb-8"
        id="about"
      >
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-[90px] items-center">
            <div className="relative space-y-4 md:space-y-0">
              <div>
                <img
                  src={fttmanimg}
                  className="mx-auto  h-[450px] w-[550px]"
                  alt="fttmanimg"
                />
              </div>
              <div className="top-[120px] -left-20 md:absolute">
                <div className="flex gap-[17px] items-center pt-[25px] pr-[30px] pb-[21px] pl-[26px] bg-white shadow-[25px_0px_65px_rgba(54,32,152,0.11)] rounded-lg">
                  <h3 className="text-[50px] text-[#1cbe59]">
                    <span
                      className="dizme_tm_counter stop"
                      data-from="0"
                      data-to="18"
                      data-speed="2000"
                    >
                      10
                    </span>
                  </h3>
                  <span className="name  font-secondary leading-[1.3] font-medium">
                    Years of
                    <br />
                    Success
                  </span>
                </div>
              </div>
              <div className="bottom-[70px] -right-[30px] md:absolute">
                <div className="flex gap-[17px] items-center pt-[25px] pr-[30px] pb-[21px] pl-[26px] bg-white shadow-[25px_0px_65px_rgba(54,32,152,0.11)] rounded-lg">
                  <h3 className="text-[50px] text-[#6e50f0] ">
                    <span
                      className="dizme_tm_counter stop"
                      data-from="0"
                      data-to="9"
                      data-speed="2000"
                    >
                      160
                    </span>
                    K
                  </h3>
                  <span className="name  font-secondary leading-[1.3] font-medium">
                    Completed
                    <br />
                    HomePass
                  </span>
                </div>
              </div>
            </div>
            <div className="text-center md:text-left">
              <div className="mb-[25px] lg:max-w-[440px]">
                <p
                  className="text-custom text-xl md:text-2xl font-medium font-secondary wow fadeInUp"
                  data-wow-duration="1s"
                >
                  Intercom Service Provider
                </p>
               
              </div>
              <p
                className=" text-start lg:max-w-[485px] leading-[30px] mb-[47px] wow fadeInUp  text-black"
                data-wow-duration="1s"
              >
               At Sar, we specialize in providing exceptional intercom solutions tailored to your unique needs. As a leading Intercom Service Provider, we are committed to enhancing communication within your home or business with cutting-edge technology and personalized service. Our comprehensive range of services includes everything from installation to ongoing support, ensuring that your intercom system operates smoothly and effectively. We offer state-of-the-art intercom systems designed to deliver clear audio and seamless connectivity, making communication simple and efficient. Whether you’re looking to improve communication across different rooms in your home, streamline office interactions, or manage communications in a commercial setting, our team of experts is here to help. We understand that every space is different, which is why we provide customized solutions that fit your specific requirements and enhance your overall communication experience. With Sar, you can expect high-quality service and reliable performance. Our goal is to make sure that your intercom system not only meets but exceeds your expectations. We take pride in our ability to deliver solutions that are both practical and effective, ensuring that you stay connected with ease. Explore how our intercom services can benefit your space and contact us today to learn more. Let us help you find the perfect intercom solution that aligns with your needs and enhances your communication capabilities.

              </p>
              <div className="wow fadeInUp" data-wow-duration="1s"></div>
            </div>
          </div>
        </div>
        <div
          className="left-10 top-5 absolute hidden lg:block wow fadeInLeft"
          data-wow-duration="1s"
        >
          <img src={designe} alt="designe" />
        </div>
      </section>
      <div id="section-counter " className="  mt-16">
        <div className="section pt-8">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="sigma_about style-6 mb-5 mb-lg-0">
                  <div className="section-title">
                    <h3 className="title">WHY FTTH? </h3>
                  </div>

                  <div className="style-2 mt-4 mb-0">
                    <ul className="list-disc text-black tracking-tighter ">
                      <li className=" text-black">
                        FTTH stands for "Fiber To The Home", a type of
                        communication network with fiber optic cable
                      </li>
                      <li>
                        Provides faster connection speed than twisted pair
                        conductors, various applications based on up-to-date
                        technology and optical fiber cable with minimized CAPEX
                        and OPEX
                      </li>
                      <li>
                        In order to be classified as FTTH, the access fiber must
                        cross the boundary of the subscriber's premises and
                        terminate inside the premises or at the external wall
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <img className="w-full h-auto" src={gif} alt="gif" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" pt-4 bg-[#CEE3F9] " style={{ paddingBottom: "100px" }}>
        <div className="container">
          <div className=" p-5">
           
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="row">
                <li>As the best intercom services provider in Noida, come discover our superior intercom solutions. Whether for personal or commercial areas, we are committed to improving your communication and security with intercom systems that meet a variety of needs.
                </li>
                <br />
                <li>Our approach is simple: we deliver intercom systems that are customized to fit your unique requirements. From basic audio setups for smaller homes to advanced video intercom solutions for large commercial properties, our offerings are designed to provide seamless connectivity and robust security.
                </li>
                <li>Equipped with state-of-the-art technology, our intercom systems ensure that you experience clear audio and high-definition video. This means better communication and enhanced safety, allowing you to manage access and monitor activities with ease.
                </li>
               <li>Our team of experts is committed to delivering a smooth installation process and providing reliable ongoing support. We handle everything from setup to troubleshooting, ensuring your system remains in excellent working condition.
               </li>
               <li>With our intercom systems, you can enjoy peace of mind knowing that you have a reliable solution for your communication and security needs. Contact us today to find out how we can assist you in transforming your home or business with our innovative intercom solutions in Noida.
               </li>
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="sigma_info-image  ">
                <img
                  src={ftthbuilding}
                  className="w-100 lg:ml-24 ml-0"
                  alt="ftthbuilding"

                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="py-8">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={12}>
              <div className="mb-4">
                <h5>INTEGRATED TECHNOLOGY WITH GPON</h5>
                <ul className="list-disc text-black">
                  <li>
                    The increasing demand for bandwidth due to video streaming,
                    content sharing, social media applications and online gaming
                    is constantly pushing the requirement for higher bit rates
                    and better experiences
                  </li>
                  <li>
                    The ultimate response from operators, governments and
                    utilities is to deploy fiber-to-the- home (FTTH) using GPON
                    technology, which provides ultra broadband access for home
                    and office users
                  </li>
                  <li>
                    We offer state-of-the-art GPON system which enables
                    reliable, secure and future proof multi service access
                    network suitable for high bandwidth applications such as
                    video conferencing and remote education
                  </li>
                  <li>
                    GPON system complies with the latest international standards
                    and works in harsh environmental conditions
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="text-center flex justify-center items-center w-full h-full">
                <img
                  src={FTTHIMGE2}
                  alt="FTTHIMGE2"
                  className="img-fluid w-full"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div id="section-feature  " className=" bg-[#445A8B] text-white">
        <div className="section section-padding pt-16">
          <div className="container">
            <div className="section-title   has-element">
              <span className=" text-start text-white">
                SAR TELEVENTURE FTTX: SOLUTIONS OFFERED
              </span>
            </div>
            <div className="row   pt-4 text-white">
              <div className="col-lg-6">
                <ul className=" list-disc">
                  <li>
                    An integrated and innovative network solutions allow the
                    transmission of network through optic fiber medium <br />
                    <br />
                  </li>
                  <li>
                    Communities can choose any network provider according to
                    their need and we will amplify the user experience with the
                    convenience of our optical cables that will carry the
                    network to the user location <br /> <br />
                  </li>
                  <li>
                    There are no charges that are levied on the customer apart
                    from the specific plan or network connection that they
                    choose
                  </li>
                </ul>
              </div>

              <div className="col-lg-6 mb-30  mt-4 mt-lg-0 ">
                <div className=" ">
                  <img
                    src={ftthbuilding}
                    className="img-fluid "
                    alt="ftthbuilding"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white  flex  flex-col">
        <h4 className=" w-full h-auto flex justify-center items-center  mt-16">
          Network Planning, Design and Engineering
        </h4>
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-1 xl:gap-0 lg:py-16 lg:grid-cols-12  gap-4">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-2xl font-bold tracking-tight leading-none md:text-2xl xl:text-2xl ">
              SAR Televenture excels in delivering high-quality network
              planning, design, and engineering solutions.
            </h1>
            <div className="max-w-2xl mb-6 font-light text-black lg:mb-0  ">
              <ul className="list-disc">
                <li>
                  Our extensive experience managing complex networks for major
                  telecom providers translates into a deep understanding of
                  client needs and challenges.
                </li>
                <li>
                  Our skilled team ensures flawless Service Fulfillment,
                  guaranteeing end-to-end execution of customer orders.
                </li>
                <li>
                  Committed to excellence, employing industry best practices and
                  Continuous Improvement Plans, consistently exceeding client
                  expectations through service delivery aligned with agreed KPIs
                  and SLAs
                </li>
                <li>
                  SAR Televenture offers a comprehensive approach, covering
                  every aspect from initial feasibility studies to final
                  handover. This ensures a seamless and successful client
                  experience, fostering lasting partnerships
                </li>
              </ul>
            </div>
          </div>

          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img alt="networkimg" src={networkimg} />
          </div>
        </div>
      </section>
    </>
  );
}

export default InterServicesProviderInNoida;
