import React from "react";
import { HiBellAlert } from "react-icons/hi2";
import { MdSchedule } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";


import benifitimg from "../../assets/imagenew/Benefits.jpg";
import videoimg from "../../assets/imagenew/about-img1.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import OurServices from "../OurServices";
function Jaipur() {
  return (
    <>
     <Helmet>
        <meta
          name="title"
          content="Security Camera Company in Jaipur | Small Cell | Sar Televenture"
        />

        <meta
          name="description"
          content="Sar Televenture, a leading security camera company in Jaipur, specializes in FTTH infrastructure, tower infrastructure, home automation, and fire alarms."
        />

        <meta
          name="keywords"
          content="tower infrastructure company in Jaipur,tower infrastructure company in Jaipur,tower company in Jaipur,telecom tower infrastructure company in Jaipur,cell tower infrastructure companies in Jaipur,infra tower company  in Jaipur,Ftth infrastructure company in Jaipur,Best ftth infrastructure company in Jaipur,Small Cell Infrastructure in Jaipur,Small cell infrastructure company in Jaipur,Intercom services in Jaipur,Intercom service provider in Jaipur,tower installation company in Jaipur,all Jaipur tower company,Jaipur tower company,top tower company in Jaipur,Tower Installation Service in Jaipur,tower infrastructure company in Jaipur,Best tower company in Jaipur,telecom tower infrastructure company in in Jaipur,telecom tower infrastructure company in Jaipur,cell tower infrastructure companies in Jaipur,infra tower company in Jaipur,Ftth infrastructure company in Jaipur,Small Cell Infrastructure in Jaipur,Intercom services in Jaipur,Intercom service provider in Jaipur,Energy Management Solutions in Jaipur,Fire Alarm Solution in Jaipur,Fire Alarm System in Jaipur,Fire Detection And Fire Alarm System in Jaipur,Fire Monitoring System in Jaipur,Home Automation And Security System in Jaipur,Home Automation Company in Jaipur,Home Automation Solutions in Jaipur,Prepaid Energy Meter in Jaipur,Security Camera Company in Jaipur,Security Solution in Jaipur,Smart CCTV Solutions in Jaipur,Smart Home Automation in Jaipur,Smart home solutions in Jaipur,Smart Home Systems in Jaipur,Smart Prepaid Meter in Jaipur,Intercom Service Provider in Jaipur,smart automation in Jaipur,home automation iot in Jaipur,smart home automation in Jaipur,home domotics in Jaipur,Access Control Systems in Jaipur,Boom Barrier Integrated Solutions in Jaipur,CCTV Service in Jaipur"
        />
      </Helmet>
   <div
        className="inner-banner"
        style={{
          backgroundImage: `url(https://images.squarespace-cdn.com/content/v1/587e1dd33a04118eabe07a9d/1485147131500-98TSRSF5EH877OSFBIOP/telecom_banner.jpg?format=2500w)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="inner-title">
            <h1
              className=" sm:font-bold md:font-bold  sm:text-[90px]  md:text-[90px]  "
            style={{color:"Black"}}
            >
            </h1>
            <ul>
              <li
                className="  font-bold text-[68px] "
                style={{color:"Black"}}
              >
              </li>
            </ul>
          </div>
        </div>
      </div>
    <div>
      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-play">
                <img src={videoimg} alt="About-Images" />
                <div className="about-play-content">
                  <h1 className="d-none">Smart Home Solution </h1>
                  <span>Watch Our Intro Video</span>
                  <h2>Perfect Solution for Home Automation !</h2>
                  <div className="play-on-area">
                    <Link to="#" className="play-on popup-btn">
                      <i className="bx bx-play"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content ml-25">
                <div className="section-title">
                
                  <h2>Accelerate Your Business Growth with Our Cutting-Edge Solutions
                  </h2>
                  
                  <p>As pioneers in Jaipur, we specialize in the design, deployment, integration, and management of advanced communication networks. Our all-encompassing platform provides high-performance fiber systems, guaranteeing durable and scalable solutions. By partnering with India’s foremost developers, we facilitate the creation of future-ready homes equipped with premier digital services, including IP and IoT technologies, comprehensive security systems, energy management, and smart home automation—tailored to meet individual requirements and enhance modern living experiences.
                  </p>
                </div>
                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="choose-area pt-100 pb-70  "
      style={{ backgroundColor: "gray" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="mr-auto place-self-center lg:col-span-7">
             
              <h4 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
              Comprehensive Infrastructure and Security Solutions in Jaipur
              </h4>
              <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0 ">
                <ul className="list-disc text-white">
                  <ul>We offer a comprehensive range of infrastructure and security services in Jaipur, designed to enhance both residential and commercial environments. Specializing in tower infrastructure and telecom tower infrastructure, we ensure reliable communication networks that meet the demands of modern connectivity. Our FTTH infrastructure solutions deliver high-speed fiber optics for seamless internet access, while our small cell services optimize wireless performance and coverage. Security is a top priority; our advanced video door phone systems, CCTV services, and access control solutions guarantee safety for your property. Additionally, our home automation services integrate smart technologies for convenience and efficiency, making living spaces more comfortable. Our boom barrier systems effectively manage access points and control traffic flow, ensuring a secure environment. As a trusted security camera company in Jaipur, we are dedicated to providing tailored solutions that address your unique needs, helping to create safe, efficient, and technologically advanced spaces throughout the city.
                  </ul>
                  
                 
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="choose-img">
              <img src={benifitimg} alt="Images" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <OurServices/>
    <section className="   border bg-[#D5E5F4] ">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className=" w-full mb-8 lg:mb-16 flex justify-center items-center flex-col">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-[#0071DC] ">
            Features
          </h2>
          <p className="text-black sm:text-xl ">
            Wire-free Control | Real-time Alerts | Easy Scheduling
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 ">
          <div className="  ">
            <CiMobile3 className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC]  mt-4">
              App Control
            </h3>
            <p className="text-black ">
              Control the entire ecosystem of your smart home from anywhere,
              anytime with our user-friendly phone app with a single touch.
            </p>
          </div>
          <div>
            <HiBellAlert className="text-[#0071DC] text-4xl " />

            <h3 className="mb-2 text-xl font-bold text-[#0071DC]  mt-4">
              Alert
            </h3>
            <p className="text-black ">
              Get live security alerts directly sent to your phone & keep
              track of all that’s happening at your home. Stay stress-free on
              vacations with your home’s security ensured.
            </p>
          </div>
          <div>
            <MdSchedule className="text-[#0071DC]  text-4xl" />

            <h3 className="mb-2 text-xl font-bold  text-[#0071DC] mt-4">
              Scheduling
            </h3>
            <p className="text-black ">
              Schedule the run-time of all the appliances at your smart home.
              Pre-set lights, fans, air-conditioner, geyser and television to
              turn ON/OFF at a specific time
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
  );
}

export default Jaipur;
